.liftDashBoardFont {
    font-size: 14px!important;
    font-family: 'Lato'!important;
    // font-family: 'Lato-Semibold'!important;
    width: 100%;
}
.boosterFriendsLiftOnTheWay {
    font-size: 14px!important;
    // font-family: 'Lato'!important;
    text-transform:uppercase!important;
    font-family: 'Lato-Semibold'!important;
    padding-bottom:10px;
}
.messageBodyList > ul {
    margin-top: 20px;
    margin-bottom: 20px;
} 
.messageBodyList > ul >li {
    list-style-type: disc;
    margin-left: 30px;
} 
.liftDashBoardCol75 {
    width: 75%;
    margin-top: 10px;
}
.liftDashBoardCol25 {
    width: 25%;
    margin-top: 10px;
}
.liftDashBoardCol60 {
    width: 60%;
    margin-top: 10px;
}
.liftDashBoardCol40 {
    width: 40%;
    margin-top: 10px;
}
.liftDashBoardCol {
    width: 50%;
    margin-top: 10px;
}

.liftDashBoardColLeft {
    width: 46%;
    margin-top: 10px;
}
.liftDashBoardColRight {
    width: 54%;
    margin-top: 10px;
}
.liftDashBoardContainer {
    width: 100%;
    max-width: 550px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 1000px) {
    
       width: 310px;
       margin: 0 auto;
      
        
      }
}
.liftDashboardTitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}
.liftDashBoardOrangeBorder {
    border: $warning solid 1px;
    border-radius: 11px;
    padding: 5px;
    margin-right: 20px;
    width: calc(50% - 20px);
}
.liftDashboardBtnHalfRightContainer {
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row-reverse;
}
.liftDashBoardBtnHalfRight {
    border-radius: 25px 0px 0px 25px;
    width: 167px!important;
    height: 77px!important;
    font-family: "Lato";
    font-size: 16px;
    font-weight: bold;
    white-space: pre-wrap;

}
.liftDashboardBotton {
    padding-bottom: 80px;
}
.dashboardBtnSpace {
    height: 73.8px;
    background-color: white;
    @media screen and (max-width: 1000px) {
        height: 0px;
    }
}
.liftImageFullyPaid {
    width: 450px;
    height: 449px;
    margin: 0 auto;

    position: relative;
    @media screen and (max-width: 1000px) {
        width: 251px;
        height: 251px;
        margin-top: 40px;
    }
}
.barcodeImage {
    width: 144.25px;
    height: 50.54px;
    margin: 0 auto;
    margin-top: 28px;

    position: relative;
    @media screen and (max-width: 1000px) {
        width: 68.49px;
        height: 24px;
        margin-top: 40px;
    }
}
.boosterCodeText{
    font-size: 20px;
    font-family: "Lato-Semibold";
    margin-top: 20.5px;
    @media screen and (max-width: 1000px) {
        font-size: 12px;
        font-weight: normal;

    }
}
.timeForBoosterEnroll {
    font-size: 14px;
    font-weight: bold;
    max-width: 226px;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
        font-size: 12px;
        font-weight: normal;
    }
}
.timeForBoosterEnrollDays{
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
    
    @media screen and (max-width: 1000px) {
        font-size: 12px;
        
    }
}
.liftDashBoardWhiteBox2 {
    height: auto!important;
    margin-top: 0px!important;
}
.liftDashBoardWhiteBox3 {
    @media screen and (max-width: 1000px) {
        height: auto!important;
        padding-bottom: 40px!important;
        
    }
    
}
.liftDashBoardWhiteBox4 {
    padding-left: 10px;
    padding-right: 10px;
    height: auto!important;
    @media screen and (max-width: 1000px) {
        height: auto!important;
        padding-bottom: 40px!important;
        
    }
    
}
.liftDashBoardTopSpace {
    margin-top: 80px;

}
.boosterWhiteBox {
    background-color: white;
    margin-top: 61px;
    padding: 20px 10px;
    padding-top: 20px!important;
    padding-bottom: 40px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 12px;
    width: 550px;
    
    color: $primary;
    display: flex;
    justify-content: center;
    margin-left: 122px;
    @media screen and (max-width: 1000px) {
        width: 307px;
        
        margin-left: 0px;
        
        margin: 0 auto;
        margin-top: 18px;

    }
}
.boosterWhiteBox2 {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px) {
        margin-left: auto;;
    }
}
.boosterDashboardImage {
    width: 370px;
    height: 370px;
    @media screen and (max-width: 1000px) {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 253px;
        height: 253px;
    }
}
.liftDashboardImage {
    width: 315px;
    height: 315px;
    @media screen and (max-width: 1000px) {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 253px;
        height: 253px;
    }
}
.liftImageFullyPaidText{
    position: absolute;
    font-size: 50px;
    font-weight: bold;
    color: #F06E62;
    left: 82px;
    top: 135px;
    @media screen and (max-width: 1000px) {
        font-size: 30px;
        left: 47px;
        top: 77px;
    }
}
.liftImageFullyPaidText2{
    position: absolute;
    font-size: 28px;
    font-weight: bold;
    width: 328px;
    height: 62px;
    line-height: 62px;
    background-color: $primary;
    color: white;
    text-align: center;
    left: 63px;
    top: 236px;
    @media screen and (max-width: 1000px) {
        font-size: 16px;
        width: 166px;
        height: 31px;
        line-height: 31px;
        left: 49px;
        top: 133px;
    }
}
.liftImageFullyPaidText3{
    margin-top: 40px;
    font-size: 18px;
    text-transform: uppercase;
    @media screen and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 31px;
    
    }
}
.liftDashboardRequestLiftBtn{
    width: 303px;
    height: 65px;
    font-size: 18px;
    background-color: $primary!important;
    color: white;
    margin-top: 21px;
    text-transform: uppercase;
    @media screen and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 20px;
        width: 194px;
        height: 42px;
    
    }
}
.liftDashboardNote {
    margin-top: 20px;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Lato-Semibold';
}

.rejectLiftText{
    margin-bottom:12px;

}
.rejectLiftText1 {
    font-size: 16px;
    margin-top: 32px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 26px;

    }
}
.rejectLiftText2 {
    font-size: 14px;
    margin-top: 32px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 1000px) {
        font-size: 10px;
        margin-top: 22px;

    }
}
.rejectLiftText3 {
    font-size: 14px;
    margin: 0 auto;
    margin-top: 8px;
    text-align: center;
    text-transform: uppercase;
    width: 360px;
    

    @media screen and (max-width: 1000px) {
        font-size: 10px;
        margin-top: 17px;
        width: 256px;

    }
}
.rejectLiftText4 {
    font-size: 14px;
    
    margin-top: 42px;
    text-align: center;
    text-transform: uppercase;
    
    

    @media screen and (max-width: 1000px) {
        font-size: 11px;
        margin-top: 38.6px;
        

    }
}
.liftDashboardCircleDollar {
    height: 72px;
    width: 72px;
    border:$warning solid 2px;
    border-radius: 72px;
    line-height: 72px;
    text-align: center;
    background: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.liftDashboardDollarSign {
    height: 32px;
}
.underlineText {
    font-family: "Lato-Semibold";
    text-decoration: underline!important;
}
.liftDashboardTopName {
    font-size: 35px;
    font-family: "Lato";
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 8px;
    
    @media screen and (max-width: 1000px) {
        font-size: 20px;
        font-weight: bold;
        margin-top: 9px;
        margin-bottom: 0px;
        
    }
    
}
.liftDashboardTopText {
    font-family: "Lato-Semibold";
    font-size: 14px;
    @media screen and (max-width: 1000px) {
        font-size: 10px;
        color: #707070;
    }

}
.liftDashboardTopInfo {
    font-size: 12px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.liftDashboardTop {
    text-align: center;
    padding-top: 70px;
    letter-spacing: 0.33px;
    color: white;
    width: 550px;
    margin-left: 122px;
    @media screen and (max-width: 1000px) {
        margin-left: 0px;
        padding-top: 0px;
        width: 100%;
        color: $primary;
    }
}
.rejectLiftTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: "Lato";
    text-align: center;
    text-decoration: underline;
    margin-top: 29px;
    @media screen and (max-width: 1000px) {
        font-size: 14px;
        margin-top: 16px;
    }
}

.liftDashBoardLiftInfoNumber {
    font-size: 28px;
    font-weight: bold;
    text-align:center;
    margin-top: 4px;
    @media screen and (max-width: 1000px) {
        margin-top: 0px;
        font-size: 22px;

    }
}
.liftDashBoardLiftInfoPs {
    font-size: 14px;
    width: 64px;
    /* display: flex; */
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: 1000px) {
        
        font-size: 10px;
        width: 100%;

    }
}
.liftDashBoardBoosterLiftInfoPs {
    font-size: 8px;
}

.liftDashSoftError {
    font-size: 12px;
    font-weight: bold;

}

.liftDashBoardLiftInfoBox {
    background-color: white;
    border-radius: 20px;
    padding: 12px 0px;
    color: $primary;
    width: 128px;
    height: 109px;
    @media screen and (max-width: 1000px) {
        width: 97px;
        height: 82px;
        
    }

}
.liftDashBoardBoosterLiftInfoBox {
    background-color: $primary;
    border-radius: 20px;
    padding: 12px;
    color: white;
    width: 120px;
    text-align: center;
}
.liftDashBoardBoosterInfo {
    font-size: 12px;
    text-transform: uppercase;
    color: $primary;
    margin-top: 20px;
    letter-spacing:0.33px;
}
.liftDashBoardBoosterContainer {
    margin-top: 30px;
    text-align: center;
}
.liftDashBoardBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    
}
.liftDashBoardBtnContainerCenter {
    display: flex;
    justify-content: center;
    
}
.liftDashboardQuestionContainer {
    
    
    letter-spacing:0.33px;
    background-color: white;
    margin-left: 122px;
    width: 550px;
    @media screen and (max-width: 1000px) {
        margin-left: 0px;
        width: 100%;
        padding-top: 40px!important;
        position: relative;
       
        padding-left: 15px;
        padding-right: 20px;
        background-color: inherit;
       
    }
}
.dashboardFaqBox {
    position: relative;
}


.liftBoosterOnBoardingQuestionContainer{
    max-width: 300px;
    margin: 0 auto;
    letter-spacing:0.33px;
}

.liftDashboardQuestionRow {
    padding-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: $primary;
    display: flex;
}
.liftDashboardQuestionRow {
    font-size: 18px;
    @media screen and (max-width: 1000px) {
        font-size: 11px;
    }
    
}
.liftDashboardQuestionRowSign {
    margin-bottom:10px;
    height: 46px;
    margin-right: 10px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
        height: 26px;
    }
}
.liftDashboardQuestionMainContainer {
    display: flex;
    background-color: white;
    @media screen and (max-width: 1000px) {
        background-color: inherit;
        flex-direction: column;
    }
}
.liftDashboardQuestionRowInfo {
    height: 46px;
    
    line-height: 46px;
    @media screen and (max-width: 1000px) {
        
        height: 26px;
        line-height: 26px;
    }
}

.liftDashboardQuestionRowInfo2 {
    height: 26px;

    line-height: 18px;

}
.liftDashboardQuestionRowTableInfo{
    font-size: 11px;
    font-family:"Roboto";
    color: $primary;

}



.moreFaqBtn {
    background-color: white!important;
    color: $primary!important;
    font-size: 12px!important;
    font-family: "Lato"!important;
    width: 166px!important;
    height: 40px!important;
}
.moreFaqBtnSign {
    width: 20px;
    height: 20px;
    background-color: $warning;
    color: white;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 10px;
}
.moreFaqBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    margin-left: 40px;
    @media screen and (max-width: 1000px) {
        margin-left: 0px;
        align-items: center;
    }
}
.moreFaqBtnContainer2 {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    flex-direction: column;
    background-color: white;
    align-items: center;
    @media screen and (max-width: 1000px) {
        margin-left: 0px;
        align-items: center;
        background-color: inherit;
    }
}
.dashboardQuestionBtn{
    width: 303px;
    height: 65px;
    font-size: 18px;
    background-color: $primary!important;
    color: white;
    margin-top: 41px;
    @media screen and (max-width: 1000px) {
        width: 211px;
        height: 50px;
        font-size: 14px;
        
        margin-top: 29px;

    }

}
.liftDashBoardProgressContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}
.liftDashBoardProgress {
    border-radius: 10px!important;
    background-color: white;
}
.liftDashBoardProgressInfo {
    font-size: 14px;
    margin-top: 20px;
}
.liftDashBoardProgressOrangeCircle {
    margin-top: 4.5px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: $warning;
}
.liftDashBoardProgressInfoRow {
    display: flex;
}
.liftDashBoardProgressInfoDate {
    margin-left: 15px;
}
.liftDashBoardBoosterName {
    
    text-align: center;
    margin-top: 35px;
    text-transform: uppercase;
    font-size: 20px;
    @media screen and (max-width: 1000px) {
        margin-top: 33px;
        font-size: 12px;
    }
}
.liftDashBoardBoosterName2 {
    
    text-align: center;
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 16px;
    @media screen and (max-width: 1000px) {
       
        font-size: 12px;
    }
}
.liftDashBoardBoosterPayment {
    
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 20px;
    @media screen and (max-width: 1000px) {
        margin-top: 8px;
        font-size: 12px;
    }
}
.liftDashBoardBoosterPayment {
    text-align: center;
    margin-top: 19px;
    text-transform: uppercase;
    font-size: 20px;
    @media screen and (max-width: 1000px) {
        margin-top: 9px;
        font-size: 12px;
    }

}
.liftDashBoardPIFPContainer {
    text-align: center;
}
.liftDashBoardPIFPTitle1 {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 20px;
    
}
.liftDashBoardPIFPInfo1 {
    font-size: 14px;
    margin: 10px 0px;
    font-weight:bold;
    letter-spacing:0.33 px;
}
.liftDashBoardPIFPTitle2 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    
    
}
.liftDashBoardPIFPInfo2 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing:0.33px;

    margin: 10px 0px;
    margin-bottom: 20px;
    @media screen and (max-width: 1000px) {
    
        font-size: 12px;
      
        
    }
}
.liftDashBoardBoosterInfo {
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family:'Lato-Semibold'
    
    
}
.paybackBtn {
    margin-top: 30px;
    background-color:$primary!important;
    color: white!important;
    padding: 23px 35px!important;
    font-size: 16px;
    letter-spacing: 0.1rem;
    width: 240px;
    height: 64px;
    @media screen and (max-width: 1000px) {
    
        // background-color: white!important;
        // color: $primary!important;
        width: 217px;
        height: 58px;
      
        
    }

}
.pickupLiftBtn {
    margin-top: 15px;
    background-color:$primary!important;
    color: white!important;
    padding: 23px 35px!important;
    font-size: 16px;
    letter-spacing: 0.1rem;
    width: 240px;
    height: 64px;
    @media screen and (max-width: 1000px) {
    
        // background-color: white!important;
        // color: $primary!important;
        width: 172px;
        height: 43px;
        background-color:white!important;
        color: $primary!important;
        font-size: 13px;
        margin-top: 25px;
      
        
    }

}
.paybackBtn.is-loading{
    box-shadow: 5px 10px 20px rgb(155, 148, 148)!important;
}

.requestBtn {
    margin-top: 30px;
    background-color: white!important;
    color: $primary!important;
    margin-left: 60px;
    margin-right: 60px;
    // margin-top: 70px;
    // margin-bottom:90px;
    font-size: 12px;
    letter-spacing: 0.1rem;

}
.redMessage {
    color: red!important;
}

.cancelBtn {
    text-decoration: underline;
    font-family:'Lato-Semibold';
    font-size: 14px;
    letter-spacing: 0.03rem;
    color: $primary!important;
    text-align: center;
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }

}




// #nextBtn.nextBtn.is-loading{
   
//     background-color: green!important;
    
    
// }
#darkLoading.is-loading::after{
    animation: spinAround 500ms infinite linear!important;
    border: 2px solid $primary!important;
    border-radius: 290486px!important;
    border-right-color: transparent!important;
    border-top-color: transparent!important;
    content: ""!important;
    display: block!important;
    height: 1em!important;
    position: relative!important;
    width: 1em!important;
    position: absolute!important;
    left: calc(50% - (1em / 2))!important;
    top: calc(50% - (1em / 2))!important;
        
}
#darkLoadingNextBtn.is-loading::after{
    animation: spinAround 500ms infinite linear!important;
    border: 2px solid $primary!important;
    border-radius: 290486px!important;
    border-right-color: transparent!important;
    border-top-color: transparent!important;
    content: ""!important;
    display: block!important;
    height: 1em!important;
    position: relative!important;
    width: 1em!important;
    position: absolute!important;
    left: calc(50% - (1em / 2))!important;
    top: calc(50% - (1em / 2))!important;
    @media screen and (max-width: 769px){
        animation: spinAround 500ms infinite linear!important;
        border: 2px solid white!important;
        border-radius: 290486px!important;
        border-right-color: transparent!important;
        border-top-color: transparent!important;
        content: ""!important;
        display: block!important;
        height: 1em!important;
        position: relative!important;
        width: 1em!important;
        position: absolute!important;
        left: calc(50% - (1em / 2))!important;
        top: calc(50% - (1em / 2))!important;
    }
   
    
    
}

.liftDashBoardWhitePaymentBox{
    background-color: white;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 50px!important;
    padding-bottom: 30px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 12px;
}


.liftDashBoardWhiteStripInfoBox{
    background-color: white;
    margin-top: 97.4px;
    margin-bottom: 0px;
    padding-top: 50px!important;
    padding-bottom: 0px;
    border-radius: 65px;
    font-size: 12px;
    padding-right:0px;
    width:500px;
    height: 628px;
    padding: 60px;
    @media screen and (max-width: 1000px) {
        padding: 24px;
        background-color:inherit;
        width: 100%;
        margin-top:0px;
        padding-top: 0px!important;
    }

}


.liftDashBoardWhiteBox {
    background-color: white;
    margin-top: 61px;
    padding: 20px 10px;
    padding-top: 0px!important;
    padding-bottom: 40px;
    border-radius: 100px;
    text-transform: uppercase;
    font-size: 12px;
    width: 550px;
    height: 400px;
    color: $primary;
    @media screen and (max-width: 1000px) {
        width: 307px;
        
        
        margin: 0 auto;
        margin-top: 18px;
        background-color: white;

    }
}
.dashboardBoosterInfo {
    font-size: 20px;
    color: white;
    width: 550px;
    @media screen and (max-width: 1000px) {
        width: 100%;
    
        font-size: 12px;
        color: $primary;


    }

}
.dashboardBoosterSpace {
    height: 79px;

    @media screen and (max-width: 1000px) {
        height: 0px;


    }
}
.liftDashBoardNotification{
    
    color: white;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    // margin-top: 15px;
    margin-bottom: 65px;
    height: 24px;
    line-height: 24px;
    width: 550px;
    margin-left: 122px;
    @media screen and (max-width: 1000px) {
        margin-left: 0px;
        background-color: $primary;
        margin-top: 37px;
        margin-bottom: 23px;
        width: 100%;

    }
}
.liftDashBoardNotification2{
  
    margin-bottom: 34px!important;
   
    @media screen and (max-width: 1000px) {
       
        margin-bottom: 23px;
        

    }
}
.liftDashBoardNotificationPic {
    margin-right: 10px;
}
.liftDashBoardWhiteAmount {
    padding-top: 10px;
    font-size: 38px;
    font-weight: bold;
    @media screen and (max-width: 1000px) {
        font-size: 30px;
    }
}

.liftDashBoardWhiteAmount2 {
    margin-top: 20px;
    margin-bottom: 20px;
    
   
    @media screen and (max-width: 1000px) {
        
    }
}
.liftDashBoardWhitePendingMessage{
    padding-top: 10px;
}
.liftDashBoardWhiteAmountPIF {
    font-size: 38px;
    font-weight: bold;
    @media screen and (max-width: 1000px) {
        font-size: 30px;
    }
}
.dashboardCurrentBalance {
    font-size: 20px;
    margin-top: 6px;
    font-family: "Lato-Semibold";
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
}
.dashboardPaymentMade {
    font-size: 16px;
    margin-top: 19px;
    
    @media screen and (max-width: 1000px) {
        font-size: 12px;
        margin-top: 24px;
    }
}
.liftDashBoardNewLift {
    font-size: 16px;
}
.liftDashBoardNewLiftInfo {
    width: 205px;
    margin: 0 auto;
}
.padddingBotton0 {
    padding-bottom: 0px!important;
}
.moreSpaceInside {
    padding-bottom: 80px!important;
}