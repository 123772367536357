.registerTitleText1 {
    font-size: 15px;
    font-family: "Lato-Semibold";
    color: #A3A9AF;
    margin-top: 57.3px;
    letter-spacing: 0.0909rem;
    line-height: 21px;
    @media screen and (max-width: 1000px) {
        font-size: 11px;
        line-height: 20px;
    }
}
.registerTitleText2 {
    font-size: 34px;
    font-family: "Lato";
    font-weight: bold;
    color: $primary;
    margin-top: 9.3px;
    letter-spacing: -0.016rem;
    line-height: 40px;
   
    @media screen and (max-width: 1000px) {
        font-size: 32px;
    }
}
.registerTitleText3Container {
    display: flex;
    justify-content: center;
}
.registerTitleText3 {
    font-size: 14px;
    font-family: "Lato-Light";
    font-weight: bold;
    color: #767676;
    margin: auto 0;
    margin-top: 9.3px;
    line-height: 20px;
    width: 352px;

   
    @media screen and (max-width: 1000px) {
        width: 233px;
    }
}
.registerCheckBoxLabel {
    font-size: 16px;
    font-family: "Lato-Light";
    letter-spacing: 0.02rem;
    line-height: 22px;
    font-style: italic;
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
    
}
.registerCheckbox {
    margin-top: 32.8px;
    @media screen and (max-width: 1000px) {
        margin-top: 37.3px;
    }
}
.registerPageBtn {
    width: 223.04px!important;
    height: 62px!important;
    font-size: 16px;
    line-height: 16px!important;
    padding-bottom: 23px!important;
    padding-top: 23px!important;
    font-weight: bold;
    font-family: "Lato";
    margin-top: 29.4px;
    letter-spacing: 0.1rem;
    @media screen and (max-width: 1000px) {
      width: 189.77px!important;
      height: 52.64px!important;
      font-size: 13px;
      line-height: 13px!important;
      padding-bottom: 19.82px!important;
      padding-top: 19.82px!important;
      margin-top: 25px;
    }
  
  }
  .registerBottonText{
    font-size: 18px;
    font-family: "Lato-Light";
    color: $primary;
    letter-spacing: 0.02rem;
    line-height: 37px;
    margin-top: 29.4px;
    padding-bottom: 80px;
   
    @media screen and (max-width: 1000px) {
        font-size: 16px;
        line-height: 32px;
        margin-top: 33px;
        padding-bottom: 67.1px;
    }
  }
  .registerContainer {
    max-width: 400px;
    margin: auto;
    padding-left: 0px;
    color: $primary !important;
    font-family: "Lato" !important;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1000px) {
        font-size: 16px;
        line-height: 32px;
        // margin-top: 33px;
        padding-bottom: 0px;
    }
  }