.settingNav {
  width: 320px;
  display: flex;
  border-bottom: 1px solid #e2e8ed;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
}
.settingNavIcon {
  width: 19px;
  cursor: pointer;
}
.settingNavTitle {
  font-size: 20px;
  font-family: "Lato";
  color: $warning;
  text-align: center;
  margin-left: 115px;
  line-height: 15px;
  text-transform: uppercase;
}
.settingRow {
  display: flex;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 10px;
  height: 60px;
}
.settingRowTitle {
  margin-left: 20px;
}
.settingRowInfo {
  padding-top: 10px;
  font-size: 12px;
  text-align: left;
  height: 60px;
}
.settingBorder {
  border-bottom: 1px solid #e2e8ed;
}
.settingRowInfoItem {
  padding-top: 5px;
}
.settingInput {
  width: 100%;
  height: 25px;
  border: None !important;
  border-bottom: 1px solid #e2e8ed !important;
}
.settingRowBank {
  display: flex;
  padding-top: 15px;
  border-bottom: 1px solid #e2e8ed !important;
}
.settingArrow {
  margin-left: 140px;
}
.settingBtn {
  background-color: $primary;
  color: white;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-family: "Lato-Semibold";
  border: 0px;
  cursor: pointer;
}

.settingBtn:hover,
.settingBtn:active,
.settingBtn:visited,
.settingBtn:focus {
  background-color: $primary;
  color: white !important;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-family: "Lato-Semibold";
  border: 0px;
  cursor: pointer;
}
.settingBtn.is-loading {
  background-color: $primary;
  color: white;
}
.settingBtnIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.is-loading > .settingBtnIcon {
  display: none;
}
.settingRowHand {
  cursor: pointer;
}
.settingStripeInfoTitle {
  font-size: 20px;
  font-family: "Lato";
  color: $warning;
  text-align: center;
  margin-right: 17px;
  line-height: 15px;
  text-transform: uppercase;
  width: 100%;
}
.settingStripeInfoData {
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 32px;
  text-align: left;
  font-family: "Lato-Semibold";
  
  letter-spacing: 0.005em;
  text-transform: none;
  @media screen and (max-width: 1000px) {
    font-size: 13px;
  }
}
.stripeRegisterPs {
  font-size:11px;
  text-align:center;
  margin-top:27.7px;
  @media screen and (max-width: 1000px) {
    font-size: 10px;
    margin-top:24px;
  }
}

#settingStripeInfoForm > form > * > label {
  font-family: "Lato";
  font-size: 18px;
  font-weight: normal !important;
  margin-bottom: 20px;
}
#settingStripeInfoForm > form > div > div > div > div > select {
  border-radius: 13px;
}
#settingStripeInfoForm  {
  @media screen and (max-width: 1000px) {
    background-color:inherit!important;
  }
}
#settingStripeInfoForm > form  {
  width: 100%;
  @media screen and (max-width: 1000px) {
    width:100%!important;
  }
}
.my-div {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  //   padding-right: 100px;
}

.dims {
  position: absolute;
  top: -180px;
  width: 100% !important;
  height: 100vh !important;
  //   float: left !important;
  //   padding-top: -800px !important;
  //   width: 100% !important;
  //   overflow: hidden !important;
  //   height: 100vh !important;
  //   padding: 10em 0em !important;
  //   border: solid 1px red !important;
  //   padding-top: -10em !important;
  //   overflow: auto !important;
}
.dateInfo {
  width:330px!important;
  @media screen and (max-width: 400px) {
    width:100%!important;
  }
}
.dateInfo > div > select {
  height: 55.34px;
  line-height: 45.34px;
  width: 98px!important;
  @media screen and (max-width: 1000px) {
    height: 48px;
    width: 85px!important;
    line-height: 40px;
  }
}
.dateInfo > div.select {
  height: 55.34px;
  line-height: 45.34px;
  margin-top: 10px;
  width: 98px!important;
  @media screen and (max-width: 1000px) {
    height: 48px;
    width: 85px!important;
    line-height: 40px;
  }
}

.dateInfo > div > select::after {
  
  // padding-top: 30px!important;
}
.dateInfoRow {
  font-size: 13px;
  letter-spacing: 0.02em;
  font-family: "Lato-Semibold";
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
}
.arrowBack {
  
    width: 120px;
    height: 130px;
    /* display: flex; */
    line-height: 130px;
    justify-content: center;
    padding-left: 51px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      height: 76px;
    /* display: flex; */
    line-height: 89px;
    padding-left: 24px;
  }
}
.stripeRegister > div > label {
  @extend .dateInfoRow
}