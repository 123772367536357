.orangeBox {
  box-sizing: border-box;
  font-family: "Lato" !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  
  width: 36.84px;
  height: 36.84px;
  background: $warning;
  color: white;
  border: 2px #ebebeb solid;
  border-radius: 4px;
  line-height: 32.84px;
  @media screen and (max-width: 1000px) {
    width: 28.45px;
    height: 28.45px;
    line-height: 24.84px;
  }
}
.orangeBoxReverse {
  box-sizing: border-box;
  font-family: "Lato" !important;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  width: 36.84px;
  height: 36.84px;

  background: #f3f2f3;
  color: #656665;
  border: 2px #ebebeb solid;
  border-radius: 4px;
  line-height: 32.84px;
  @media screen and (max-width: 1000px) {
    width: 28.45px;
    height: 28.45px;
    line-height: 24.84px;
  }
}
.orangeBoxOuter {
  font-family: "Lato" !important;
  font-weight: bold;
  font-size: 12px;
  
  
  display: flex;
  justify-content: space-between;

  align-items: center;
}

.orangeBoxContainer {
  text-align: center;
  margin-top: 88.4px;
  display: flex;
  width:300px;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    width:218px;
    margin-left: 51px;
    margin-top: 26.7px;
  }
}
.boosterOrangeBoxContainer {
  width: 110px;
  @media screen and (max-width: 1000px) {
    width: 78px;
  }
}
.latoBold {
  font-family: "lato";
  font-weight: bold;
  text-align: left;
}
.liftRequestTitle {
  @extend .latoBold;
  font-size: 30px;
  letter-spacing: 0.01em;
  line-height: 50px;
  margin-top: 43.9px;
  font-family: "Lato";
  font-weight: bold;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    letter-spacing: 0.04em;
    line-height: 16px;
    margin-top: 23.4px;
    margin-left: 45px;
  }
}
.liftRequestTitle2 {
  @extend .latoBold;
  font-size: 24px;
  line-height: 48px;
  margin-top: 30px;
  font-family: "Lato";
  font-weight: bold;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    letter-spacing: 0.04em;
    line-height: 16px;
    margin-top: 23.4px;
    margin-left: 45px;
  }
}
.liftRequestTitle3 {
  @extend .latoBold;
  font-size: 25px;
  
  margin-top: 15px;
  font-family: "Lato";
  font-weight: bold;
  letter-spacing: 0.01em;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
    margin-top: 23.4px;
    margin-left: 45px;
  }
}
.liftRequestTitle4 {
  @extend .liftRequestTitle;
    width: 600px;
  @media screen and (max-width: 1000px) {
    width: auto;
    margin-left: 0px;
    
  }
}
.liftRequestGap {
  @extend .latoBold;
  font-size: 16px;
  letter-spacing: 0.04em;
  margin-top: 30px;
}

.boosterCode {
  @extend .latoBold;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-align: center;
}

.orangeBorderInput {
  padding-left: 10px;
  border: $warning solid 1px !important;
  border-radius: 14px !important;
  // width: 270px!important;
  height: 31px !important;
  margin-top: 8px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
}
.liftSignInput {
  padding-left: 30px;
  border: none!important;
  border-radius: 32px !important;
  width: 360px!important;
  height: 63px !important;
  margin-top: 20.5px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;
  @media screen and (max-width: 1000px) {
    padding-left: 30px;
    margin-left: 45px;
    width: 217.23px!important;
    height: 36.78px !important;
    margin-top: 20.7px !important;
  }
}
.liftSignInputSmall {
  padding-left: 30px;
  border: none!important;
  border-radius: 32px !important;
  width: 218.77px!important;
  height: 38.06px !important;
  margin-top: 20.5px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;
  @media screen and (max-width: 1000px) {
    padding-left: 30px;
    margin-left: 45px;
    width: 169.6px!important;
    height: 29.51x !important;
    margin-top: 20.7px !important;
  }
}
.noBorderInput {
  padding-left: 27px;
  padding-bottom: 5px;

  border-radius: 14px !important;
  width: 76px !important;
  height: 31px !important;
  margin-top: 8px !important;
  border: 1px solid $primary;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  box-shadow: none;
}
.primaryBorderInput.ssnInput {
  width: 377px !important;
  height:55.34px!important;
  margin-top:10px!important;
  border-radius: 4px!important;
  @media screen and (max-width: 1000px) {
    width: 327px !important;
    height:48px!important;
  }
  @media screen and (max-width: 400px) {
    width: 100% !important;
  }
}
.primaryBorderInput {
  padding-left: 10px;
  border: $primary solid 1px !important;
  border-radius: 14px !important;
  width: 270px !important;
  height: 36px !important;
  margin-top: 8px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
}

#orangeBorderInputMoney {
  width: 111px !important;
  height: 31px !important;
  border-radius: 11px !important;
}

#orangeBorderInputFirstName {
  width: 265px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}
#orangeBorderInputLastName {
  width: 265px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}
#orangeBorderInputCity {
  width: 131px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}
#orangeBorderInputZip {
  width: 65px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}

#orangeBorderInputPhoneNumber {
  width: 265px !important;
  height: 35px !important;
  border-radius: 25px !important;
  letter-spacing: 0.03em;
}

#orangeBorderInputVerifyCode {
  width: 100px !important;
  height: 35px !important;
  border-radius: 25px !important;
}

.orangeBorderInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Lato" !important;
  font-size: 10px !important;
  font-style: italic !important;
  color: #4d5b77 !important;
  font-weight: bold !important;
}

.orangeBorderInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Lato" !important;
  font-size: 10px !important;
  font-style: italic !important;
  color: #4d5b77 !important;
  font-weight: bold !important;
}

.orangeBorderInput::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Lato" !important;
  font-size: 10px !important;
  font-style: italic !important;
  color: #4d5b77 !important;
  font-weight: bold !important;
}
.liftRequestSideNote {
  font-size: 10px;
  font-family: "Lato";
  text-align: left;
}
.formFieldOrange {
  margin-top: 15px;
}
.orangeFormField {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 271px !important;
  height: 42px !important;
}
.orangeFormField > select {
  border: $warning solid 1px !important;
  border-radius: 17px !important;
  width: 271px !important;
  height: 42px !important;
  font-family: "Lato" !important;
  font-size: 18px !important;
  padding-left: 12px;
}
.orangeFormFieldSmall {
  margin-top: 6px;
  margin-bottom: 8px;
  width: 63px !important;
  height: 31px !important;
  font-family: "Lato" !important;
  font-size: 13px !important;
}
.orangeFormFieldSmall > select {
  border: $warning solid 1px !important;
  border-radius: 5px !important;
  width: 63px !important;
  height: 31px !important;
  padding-left: 12px;
  color: $primary !important;
  font-family: "Lato" !important;

  font-weight: normal;
}

.orangeFormFieldMedium {
  margin-top: 6px;
  margin-bottom: 8px;
  // width: 210px!important;
  height: 40px !important;
  font-family: "Lato" !important;
  font-size: 14px !important;
}
.orangeFormFieldMedium > select {
  border: $warning solid 1px !important;
  border-radius: 15px !important;
  // width: 210px!important;
  height: 41px !important;
  padding-left: 12px;
  color: $primary !important;
  font-family: "Lato" !important;

  font-weight: normal;
}
.orangeFormFieldSmall:after {
  border-color: $warning !important;
}
.labelOrange {
  font-family: "Lato-Semibold";
  font-size: 14px;
  letter-spacing: 0.03em;
}
.label2 {
  display: flex;
  margin-top: 5px;
  font-family: "Lato" !important;
  font-size: 11px !important;
  letter-spacing: 0.01rem;
}
.nav-text-space {
  letter-spacing: 0.01rem !important;
}
.radioButtonVals {
  font-family: "Lato-Semibold";
  font-size: 13px;
}
.liftRocketBackground {
  //background: radial-gradient(lighten($warning, 25%), white);
  background: linear-gradient(white, rgb(232, 176, 152)) !important;
  background-size: 100% 100%;
  @media screen and (max-width: 1000px) {
    background: linear-gradient(white, darken(rgb(232, 176, 152), 20%)) !important;
  }
}
.dashboardBackground {
   //background: radial-gradient(lighten($warning, 25%), white);
   
   background: url("../svg/dashboardBackground.svg");
   background-color: $primary;
 
   background-size: 100% 100%;
   height: 1220px;
   @media screen and (max-width: 1000px) {
    height: auto;
     background: linear-gradient(white, darken(rgb(232, 176, 152), 20%)) !important;
   }
}
.newDashboardContainer {
  // width: 550px;
  
  
  @media screen and (max-width: 1000px) {
    width: 100%;
    padding-left: 0px;
    
  }
}

.liftRequestContainer {
  width: 300px;
  margin: 0 auto;
  padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  padding-left: 122px;
  @media screen and (max-width: 1000px) {
    padding-left: 0px!important;
    
  }
}
.liftRequestContainer3 {
  width: 300px;
  margin: 0 auto;
  padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    margin: 0 auto;
    
  }
}


.liftRequestContainerLeft {
  // width: 300px;
  // padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  // display: flex;
  // flex-direction: column;
  margin-left: 100px;
}
.liftRequestPageContainer {
  width: 450px;
  height: 990px;
  margin-left: 113px;
  
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 1000px) {
    // margin-left: 22.6px;
    // margin-right: 22.6px;
    width: 100%;
    height: auto;
    max-width: 330px;
    margin: 0 auto;
    margin-top: 9.8px;
    background: linear-gradient(white, lighten(rgb(232, 176, 152), 10%)) !important;
    margin-bottom: 62px;
  }
  
}
.liftRequestPageContainer3 {
  @extend .liftRequestPageContainer;
  max-width: 377px;
  
  @media screen and (max-width: 1000px) {
    // margin-left: 22.6px;
    // margin-right: 22.6px;
    width: 100%;
    height: auto;
    background:inherit!important;
    
    
  }
  
}

.liftRequestContainer2 {
  width: 270px;
  margin: auto;
  padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.liftRequestContainerWhite {
  width: 320px;
  margin: auto;
  padding-left: 5px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
}

.liftRequestContainerSmaller {
  width: 250px;
  margin: auto;
  padding-left: 5px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
}

.btnHalfRightContainer {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  @media screen and (max-width: 769px) {
    justify-content: left;
  }
}
.btnHalfRightContainer.bankBtnContainer {
  padding-bottom: 10px !important;
}
.btnHalfRight {
  border-radius: 25px 0px 0px 25px;
  width: 160px !important;
  height: 45px !important;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.02em;
  font-weight: bold;
}

.btnHalfRightSemiBold {
  border-radius: 25px 0px 0px 25px;
  width: 160px !important;
  height: 45px !important;
  font-family: "Lato-Semibold";
  font-size: 16px;
  letter-spacing: 0.02em;
}

button.button.is-rounded {
  line-height: 0;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 4em;
  padding-right: 4em;
}

.liftRequestSubTitle {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato";
  font-weight: bold;
}
.liftRequestParagraph {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 10px;
  font-family: "Lato";
}
#liftRequestParagraph1{
  width: 503px!important;
  @media screen and (max-width: 1000px) {
    width: 228px!important;
  }
}
#liftRequestParagraph2{
  width: 600px!important;
  @media screen and (max-width: 1000px) {
    width: 228px!important;
  }
}
#liftRequestParagraph3{
  width: 631px!important;
  @media screen and (max-width: 1000px) {
    width: 228px!important;
  }
}
.liftRequestParagraph2 {
  
  
  text-align: left;
  font-size: 16px;
  font-family: "Lato-Semibold";
  width: 100%;
  line-height: 30px;
  letter-spacing: 0.02rem;
  width: 544px;
  margin-top: 21.8px;
  @media screen and (max-width: 1000px) {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.02rem;
    margin-left: 45px;
    width: 228px;
    margin-top: 16.7px;
  }
}

.liftRequestParagraphReadytoHelp {
  // margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
  font-family: "Lato-Semibold";
  width: 100%;
  padding-left: 25px;
  @media screen and (max-width: 1000px) {
    margin-left: 33.4px;
  }
}

.liftRequestParagraph3 {
  margin-bottom: 5px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato";
}
.liftRequestParagraph4 {
  text-align: left;
  font-size: 11px;
  font-family: "Lato-Semibold";
  line-height: 24px;
  @media screen and (max-width: 1000px) {
    width: 300px;
    margin: 0 auto;
  }
}
.liftRequestBankBody {
  margin-top: 33px;
  text-align: left;
  font-size: 22px;
  font-family: "Lato";
  line-height: 40px;
  letter-spacing: 0.02em;
  width: 585px;
  @media screen and (max-width: 1000px) {
    margin-top: 26.6px;
  
    font-size: 14px;
    
    line-height: 25px;
    letter-spacing: 0.015em;
    margin-left: 45px;
    width: 228px;
  }
}

.liftRequestBankBody2 {
  margin-top: 33px;
  text-align: left;
  font-size: 20px;
  font-family: "Lato";
  font-weight: bold;
  line-height: 40px;
  letter-spacing: 0.02em;
  @media screen and (max-width: 1000px) {
    margin-top: 26.6px;
  
    font-size: 14px;
    
    line-height: 18px;
    letter-spacing: 0.020em;
    margin-left: 45px;
    width: 228px;
  }
}

.liftRequestNoImpact {
  margin-top: 5px;
  text-align: left;
  font-size: 19px;
  font-family: "Lato-Light";
  margin-bottom: 0px;
  letter-spacing: 0.01em;
  line-height: 23px;
  padding-bottom: 2px;
  margin-left: 44px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    margin-left: 0px;
  }
}
.extraSpace {
  padding-bottom: 40px;
}

.liftRequestParagraphContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.btnUploadPhoto {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 198px !important;
  height: 38px !important;
}
.UploadPhotoSideNote {
  font-size: 9px;
  font-weight: bold;
}
.imageUploadFrame {
  margin: 0 auto;
  width: 290px;
  height: 211px;
  border: $warning 1px solid;
  border-radius: 10px;
  background-color: white;
  margin-top: 15px;
}
#textareaOragne {
  border: $warning 1px solid !important;
  width: 290px !important;
  height: 339px !important;
  min-width: 290px !important;
}
.liftRequestShareContainer {
  display: flex;
  width: 320px;
  margin: 0 auto;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 25px;
}
.liftRequestShareBox {
  width: 105px;
  height: 105px;
  cursor: pointer;
}
.liftRequestShareBoxText {
  font-size: 12px;
  font-weight: bold;
  font-family: "Lato";
}
.liftRequestShareBoxText {
  color: $primary;
}
.liftRequestRadioContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;
}
.liftRequestRadioTitle {
  font-family: "Lato";
  font-size: 14px;
  margin-top: 20px;
}
.formRadioOrange {
  margin-top: 5px;
  font-family: "Lato";
  font-size: 10px !important;
}
.formRadioOrange > .control {
  margin-top: 5px;
  font-family: "Lato";
  font-size: 10px !important;
}
.generateQrBtn {
  width: 170px !important;
}
.generateQrBtnContainer {
  margin-top: 30px;
  text-align: left;
}
.checkCircle {
  width: 24px;
  height: 24px;
  margin-right: 7px;
}
.btnGenerateCode {
  margin-top: 20px;
  font-size: 12px;
  font-family: "Lato";
  font-weight: bold;
  width: 225px;
  height: 31px;
}
.bankCenter {
  display: flex;
  justify-content: center;
}
// .btnBank, .btnBank:visited {
//     font-size: 19px!important;
//     font-family: "Lato"!important;
//     font-weight: bold!important;
//     width: 170px!important;
//     height: 55px!important;
//     border-radius: 25px!important;
//     padding-top: 10px!important;
//     padding-bottom: 10px!important;
//     margin-top:25px;
// }

.btnBank,
.btnBank:visited {
  box-shadow: 5px 10px 20px rgb(155, 148, 148) !important;

  color: $primary !important;
  background-color: white !important;
  border-radius: 25px 25px 25px 25px !important;

  @media screen and (max-width: 769px) {
    box-shadow: none !important;
    color: white !important;
    background-color: $warning !important;
    border-radius: 25px 0px 0px 25px !important;
  }
}

#liftRequest2Input {
  width: 267px !important;
  height: 42px !important;
}

#liftRequest2InputFamilyMember {
  // width: 270px!important;
  height: 42px !important;
  // padding-left:10px;
}
#liftRequest3InputSign {
  width: 76px !important;
  height: 17px !important;
  padding-left: 25px;
  border: 1px solid $primary;
}
#liftRequest2InputSign {
  width: 265px !important;
  height: 42px !important;
  padding-left: 25px;
}
.centerDiv {
  display: flex;
  align-self: center;
  flex-direction: column;
}
.centerDiv2 {
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
}


.dollarSign {
  height: 50px !important;
  margin-top: 3.5px;
  margin-left: 2px;
  font-weight: normal;
  color: $primary !important;
}
.dollarOutside {
  margin-right: 10px;
  margin-left: 20px;
  font-size: 10px;
  font-family: "Lato";
  font-style: italic;
}
.radioYesNoInput {
  height: 31px;
}
.radioYesNo {
  height: 31px;
  line-height: 28px;
  margin-left: 5px;
  font-family: "Lato";
  font-size: 15px;
  @media screen and (max-width: 1000px) {
    font-size: 11px;
   
  
   
  }
}

.radioSometimesNotatAll {
  height: 31px;
  line-height: 28px;
  margin-left: 5px;
  font-family: "Lato";
  font-size: 15px;
  @media screen and (max-width: 1000px) {
    font-size: 11px;
   
  
   
  }
}

.liftRequestMoreSpace {
  padding-bottom: 40px;
}
.liftRequestBoosterInfo {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Lato";
  text-align: center;
  margin-bottom: 20px;
}

#liftInput {
 
  border-radius: 32px !important;
  width: 372px!important;
  height: 63px !important;
  border: none;
  padding-left: 40px;


  background-color: white !important;
  font-size: 22px !important;
  font-family: "Lato" !important;
  margin-top: 48px;
  box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;
  @media screen and (max-width: 1000px) {
    width: 217.23px!important;
    height: 36.78px !important;
    font-size: 14px!important;
    margin-top: 48.1px;
    margin-left: 47.1px;
  }
}

#liftInput::placeholder {
 
  font-size: 22px!important;
  // padding-left: 20px;
  font-weight: normal;
  color: $primary;
  font-family: "Lato";
  opacity: 1;
  @media screen and (max-width: 1000px) {
    
    font-size: 14px!important;
  }
}
#liftInput.liftRequestPageOneFirstInput{
  margin-top: 44.7px;

  @media screen and (max-width: 1000px) {
    
    margin-top: 26.7px;
    
  }
}
.liftRequestPageContainer >div > .control {
  justify-content: flex-start;
}
.liftRequestBackground {
  background-image: url("../svg/lift.svg");
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 1000px) {
    background: none;
   
  }
}
.liftRequestPageBottonSpace {
  // margin-bottom: 217px;
  @media screen and (max-width: 1000px) {
    
    margin-bottom: 47px;
  }
}
.liftRequestTwoLabel {
  display: flex;
  margin-bottom: 0;
  margin-top: 36.8px!important;
  font-family: "Lato-Semibold" !important ;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.03em;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    margin-top: 24px!important;
    margin-left: 45px;
    margin-right: 22px;
  }
}
.liftRequestTwoLabelLongText {
  display: flex;
  margin-bottom: 0;
  margin-top: 36.8px!important;
  font-family: "Lato-Semibold" !important ;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.03em;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    margin-top: 24px!important;
    margin-left: 45px;
    margin-right: 22px;
  }
}
.liftRequestTwoLabelLongText2 {
  margin-top: 35px!important;
  @media screen and (max-width: 1000px) {
    margin-top: 24px!important;
  }
}
.liftRequestTwoLabel2 {
  display: flex;
  margin-bottom: 0;
  margin-top: 15px!important;
  font-family: "Lato" !important ;
  font-size: 15px;
  font-style: italic;

  font-weight: normal;
  letter-spacing: 0.03em;
  width: 439px;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
    margin-top: 11px!important;
    margin-left: 45px;
    width: 228px;
  }
}
.liftRequestTwoLabel3 {
  
  @media screen and (max-width: 1000px) {
    
    margin-top: 9px!important;
   
  }
}
.liftRequestTextSmall {
  display: flex;
  margin-bottom: 0;
  margin-top: 18px!important;
  font-family: "Lato-Semibold" !important ;
  font-size: 15px;

  font-weight: normal;
  letter-spacing: 0.03em;
  width: 439px;
  @media screen and (max-width: 1000px) {
    font-size: 12px;
    margin-top: 11x!important;
    margin-left: 45px;
    width: 228px;
  }
}

.radioRow {
  display: flex;
  font-family: "Lato";
  
  @media screen and (max-width: 1000px) {
    
    margin-left: 45px;
   
  }
}
.radioRowFirst {
  margin-top: 16px;
}
.liftRequestTwoDollarSignLabel {
  height: 50px !important;
  margin-top: 28.5px;
  margin-left: 2px;
  font-weight: normal;
  color: $primary !important;
  @media screen and (max-width: 1000px) {
    margin-top: 13.5px;
    margin-left: 45px;
  }
}
.liftSignInput > select {
 
 
  // padding-left: 10px;
  border: none!important;
  border-radius: 32px !important;
  width: 360px!important;
  height: 63px !important;
  // margin-top: 20.5px !important;

  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  padding-left: 0px!important;
  // box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;
  @media screen and (max-width: 1000px) {
    width: 217.23px!important;
    height: 36.78px !important;
    
  }
  
}
.liftRequestError {
  font-size: 18px !important;
  @media screen and (max-width: 1000px) {
    margin-left: 45px;
   
  }
}
.field.is-expanded.has-text-left{
  margin-bottom: 0px;
}
#bankBtn2 {
  margin-top: 52px;
  margin-bottom: 82px;
  font: "Lato"!important;
  font-size: 18px!important;
  line-height: 18px;
  width: 274px!important;
  height: 68px!important;
  padding-top: 25px!important;
  padding-bottom: 25px!important;
  @media screen and (max-width: 1000px) {
    margin-top: 33.6px;
    margin-bottom: 57px;

    font-size: 10px!important;
    line-height: 10px;
    width: 150px!important;
    height: 44px!important;
    padding-top: 17px!important;
    padding-bottom: 17px!important;
  }

}
.getCopyCodeBtn {
  margin-top: 35px;
  margin-left: 0px;
  height: 41px !important;
  width: 207px !important;
  display: flex;
  font-size: 11px!important;
  font-weight: bold!important;
  letter-spacing: 0.1rem!important;
  font-weight: 35px!important;
  color: $primary !important;
  background-color: white !important;
  @media screen and (max-width: 1000px) {
    width: 114px !important;
    height: 32px !important;
    
    padding-top: 14px!important;
    padding-bottom: 14px!important;
    line-height: 12px;
    font-size: 8px!important;
    margin: 0 auto;
    margin-top: 48.1px;
    letter-spacing: 0.06rem!important;
    font-weight: normal!important;
  }
}
.liftRequest7BtnContainer {
  display: flex;
  width: 554px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
}
.liftRequest7BtnNext {
  margin-top: 35px!important;
  @media screen and (max-width: 1000px) {
    margin: 0 auto;
    margin-top: 16px!important;
  }
}
.liftrequstOneIndicator {
  margin-left: 57px;
  @media screen and (max-width: 1000px) {
    margin-left: 28px;
  }
}
.boosterPageLogin {
  text-align: left;
  margin-left: 82px;
  margin-top: 10px;
  @media screen and (max-width: 1000px) {
    font-size: 11px!important;
    margin-left: 100px;
    
    
  }
}
.checkBoxTop {
  margin-top: 20px;
  @media screen and (max-width: 1000px) {
    
    margin-left: 45px;
  }
  
}
.checkBoxTop > div >div>div >label {
  font-size: 16px!important;
  @media screen and (max-width: 1000px) {
    font-size: 11px!important;
    line-height: 20px;
    
  }
}
#boosterNextBtn {
  margin-top: 25px;
  @media screen and (max-width: 1000px) {
    margin-top: 48.1px;
  }
}
.liftBoosterLogo{
  height: 130px;
  box-shadow: 5px 10px 20px rgb(155, 148, 148) !important;
  background-color:white!important;
  background: url("../svg/liftBoosterLogo2.svg") no-repeat center;

  @media screen and (max-width: 1000px) {
    height: 76px;
    background: url("../svg/liftBoosterLogo2Mobile.svg") no-repeat center;
  }
  

}
.headerBtn{
  width: 159px;
  height: 53px;
  background-color: $primary;
  color: white;
  font-size: 18px;
  font-family: "Lato";
}
.shortWhiteHorizontalLine {
  width: 60px;
  height: 5px;
  background-color: white;
  margin-bottom: 18.6px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
.shortWhiteHorizontalLine2 {
  display: none;
  @media screen and (max-width: 1000px) {
    display: flex;
    width: 48px;
    height: 4px;
    background-color: white;
    margin: 0 auto;
    margin-bottom: 10.8px;
  }
}