@import "./lift.scss";
@import "./setting.scss";
@import "./admin.scss";
@import "./liftDashBoard.scss";
@import "./transaction.scss";
.liftRocketBody {
  max-width: 1120px;
  margin: 0 auto;
  @media screen and (max-width: 1216px) {
    max-width: 960px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 768px;
  }
  @media screen and (max-width: 769px) {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
  }
}
.navUserBtn {
  display: flex;
}
.navItemFont {
  font-family: "Lato-SemiBold";
  font-size: 18px;
  // text-transform: uppercase;
  
  color: $primary;
  margin-top: 13px;
  
}
.navItemFontOld {
  font-family: "Lato-SemiBold";
  font-size: 22px;
  // text-transform: uppercase;
  margin-right: 35px;
  
  color: $primary;
  margin-top: 13px;
  
}
.navUserBox {
  display: flex;
  margin-top: 26px;
}
.navPersonImage {
  width: 41px;
  height: 41px;
  font-size: 21px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navMainUserBox {
  display: flex;
}
.borderLessBtn {
  border: none !important;
}
.mobileNavIcon {
  width: 24px;
  height: 24px;
}
.navName {
  
  font-family: "Lato-Semibold";
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  color: $primary;
  line-height: 41px;
}
.personIconUser{
  width: 22px;
  height: 22px;;
}
.navbar {
  background-color: white !important;
  height: 130px;
}
.outsideFormLabel {
  text-align: left;
}

.outsideFormInput {
  display: flex;
}
.phoneVerifyBtn {
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  height: 40px !important;
  width: 115px !important;
  display: flex;
  font-size: 12px;
}
.phoneVerifyBtnSendCode {
  margin-top: 35px;
  margin-left: 64px;
  height: 44.84px !important;
  width: 207.28px !important;
  display: flex;
  font-size: 14px!important;
  line-height: 14px!important;
  padding-top: 20.42px!important;
  padding-bottom: 20.42px!important;
  font-weight: bold!important;
  letter-spacing: 0.1rem!important;
  font-weight: 35px!important;
  color: $primary !important;
  background-color: white !important;
  @media screen and (max-width: 1000px) {
    width: 137.23px !important;
    height: 40.73px !important;
    margin-top: 48.1px;
    padding-top: 14px!important;
    padding-bottom: 14px!important;
    font-size: 12px;
    line-height: 12px!important;
    padding-top: 14.36px!important;
    padding-bottom: 14.36px!important;
    margin-left: 81px;
    
  }
}
.liftRequestBtnNext {
  margin-top: 63px;
  margin-left: 64px;
  height: 53px !important;
  width: 190px !important;
  display: flex;
  font-size: 16px!important;
  line-height: 16px!important;
  padding-top: 18.5px!important;
  padding-bottom: 18.5px!important;
  font-weight: bold!important;
  letter-spacing: 0.1rem!important;
  font-weight: 21px!important;
  color: white !important;
  background-color: $warning !important;
  text-transform: uppercase;
  @media screen and (max-width: 1000px) {
    width: 153.99px !important;
    height: 42.72px !important;
    margin-top: 48.1px;
    padding-top: 14px!important;
    padding-bottom: 14px!important;
    line-height: 14px;
    font-size: 14px;
    margin-left: 81px;
    
  }
}
.liftRequestBtnNextPage8 {
  @media screen and (max-width: 1000px) {
    
    margin-top: 33px!important;
  
    
  }
}
.loginBtnOverride {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 40px !important;
  width: 115px !important;
  display: flex;
}

.error-msg-padding {
  padding-top: 20px;
  color: $warning;
}

.error-msg-padding:first-letter {
  text-transform: capitalize;
}

.smsBoosterCodeBtn {
  margin-top: 20px;
  width: 260px !important;
  height: 64px!important;
  font-size: 16px!important;
  background-color: $primary!important;
  color: white!important;
  display: flex;
  @media screen and (max-width: 1000px) {
    width: 172px !important;
    height: 42px!important;
    font-size: 13px!important;
    background-color: white!important;
    color: $primary!important;
    
    
}
}

progress.twoColor::-webkit-progress-value {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(50%, #ff5f58),
    color-stop(50%, yellow)
  ) !important;
  background-image: -webkit-linear-gradient(
    left,
    #ff5f58 50%,
    yellow 50%
  ) !important;
  background-image: linear-gradient(
    to right,
    #ff5f58 50%,
    yellow 50%
  ) !important;
}
progress.twoColor::-moz-progress-bar {
  background-image: -moz- oldlinear-gradient(to left, #ff5f58 50%, yellow 50%) !important;
  background-image: -moz- oldlinear-gradient(to left, #ff5f58 50%, yellow 50%) !important;
  background-image: -moz- oldlinear-gradient(to left, #ff5f58 50%, yellow 50%) !important;
}
progress.twoColor {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(50%, #ff5f58),
    color-stop(50%, yellow)
  ) !important;
  background-image: -webkit-linear-gradient(
    left,
    #ff5f58 50%,
    yellow 50%
  ) !important;
  background-image: -moz- oldlinear-gradient(to left, #ff5f58 50%, yellow 50%) !important;
}
.lrTipsNote {
  font-size: 11px;
}
.fontLato {
  font-family: "Lato" !important;
}
.liftTimeInfo {
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
}
.liftTimeInfoCountDown {
  font-size: 20px;
  font-weight: bold;
}
.liftPageInfoTitle {
  font-size: 20px;
  color: $warning;
}
.liftPageInfoPartner {
  font-size: 14px;
}
.sliderStyle {
  margin: 20px auto;
  width: 100px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  @media screen and (max-width: 1168px) {
    margin: 20px auto;
  }
}
.circleSlider {
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.is-clickable {
  cursor: pointer;
}

.checkoutSuccessWidth560 {
  min-width: 480px;
  padding: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  @media screen and (max-width: 480px) {
    min-width: 100%;
    width: 100%;
  }
}
.checkoutSuccessTitle {
  width: 100%;
  font-size: 46px;
  font-weight: bold;
  line-height: 54px;
  @media screen and (max-width: 1056px) {
    text-align: center;
  }
  @media screen and (max-width: 769px) {
    font-size: 28px;
  }
}
.checkoutSuccessInfo {
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  min-width: 430px;
  max-width: 430px;
  @media screen and (max-width: 1056px) {
    min-width: 200px;
    max-width: 480px;
    font-size: 22px;
    margin: 0 auto;
  }
}
.checkoutSuccessShareInfo {
  font-size: 28px;
  font-weight: bold;
  width: 256px;
  @media screen and (max-width: 769px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}
.btnSocailImage {
  margin-left: 5px;
  margin-right: 10px;
}
.SocailBtnShare {
  color: $primary !important;
  font-size: 11px;
  text-transform: uppercase;
  width: 128px;
  height: 40px;
}
.checkoutSuccessShareContainer {
  position: relative;
  min-width: 480px;
  height: 356px;
  background-color: white;
  color: $primary;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 480px) {
    min-width: 100%;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 25px;
  }
}
.smallTitleBox {
  position: absolute;
  background-color: $primary;
  color: white;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  top: -12px;
  border: 1px solid rgb(112, 112, 112);
  width: 200px;
  text-align: center;
}

.socailShareOrangeBox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 280px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 480px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
    height: 150px;
  }
}
.checkoutSuccessMainContainer {
  font-family: "Lato";
  margin: 0 auto;
  padding-top: 80px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-bottom: 35px;
  max-width: 960px;
  @media screen and (max-width: 1056px) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.checkoutSuccessPs {
  @extend .liftRocketBody;
  font-size: 20px;
  margin-top: 40px;
  padding-bottom: 0px;
  font-weight: normal;
  @media screen and (max-width: 1056px) {
    padding-bottom: 40px;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}
.checkoutSuccessPublicPs {
  @extend .liftRocketBody;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 0px;
  font-weight: normal;
  text-align: center;
  @media screen and (max-width: 1056px) {
    padding-bottom: 40px;
    width: 100%;
  }
  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}

.navContainer {
  @extend .liftRocketBody;
  margin: 0 auto;
}
.liftPageContainer {
  max-width: 1120px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 auto;
  @media screen and (max-width: 1216px) {
    max-width: 960px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 768px;
  }
  @media screen and (max-width: 769px) {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.leftSideContainer {
  width: 60%;

  @media screen and (max-width: 769px) {
    max-width: 768px;
    width: 100%;
  }
}
.rightSideContainer {
  width: 40%;

  @media screen and (max-width: 769px) {
    max-width: 768px;
    width: 100%;
  }
}
.rightSideContainerShadow {
  padding: 20px;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
  @media screen and (max-width: 769px) {
    padding: 0px;
    -webkit-box-shadow: 0 0 0px #999;
    box-shadow: 0 0 0px #999;
  }
}
.imageCenter {
  margin: 0 auto;
  width: 30px;
  height: 30px;
}
.margin-left-25 {
  margin-left: 25px;
}
.wdith-160 {
  width: 160px;
}
// .footer{
//   @extend .liftRocketBody;
//   background: white!important;
// }

#footerLink {
  margin-bottom: 18.6px;
  margin-top: 0px;
  font-family: "Lato-Light";
  font-size: 18px;
  color: white;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10.8px;
  }
}

.footer {
  background-color: transparent;
  color: white;
  padding: 1rem 1.5rem 1rem !important;

  @media screen and (max-width: 1000px) {
    padding-top: 0px !important;
    margin-top: 0px;
  }
}

.mobileCenterFooterItem {
  @media screen and (max-width: 1000px) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.footerLeft {
  text-align: center;
  color: white;
  font-size: 1.25rem;
}
.footerOrange {
  @extend .liftRocketBody;
}
.footerCompanyFont {
  font-size: 18px;
  font-style: italic;
  font-family: Lato;
  font-weight: bold;
  color: $primary;
  @media screen and (max-width: 769px) {
    font-size: 15px;
  }
}

.paymentSubtitle {
  font-style: italic;
  font-size: 12px;
  font-family: Lato;
  text-transform: none !important;
  padding-bottom: 10px;
  text-align: left;
}


.footerContent {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 600px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin-top: 10px;
  }
}
.footerItem {
  text-align: left;
  color: white !important;
  font-size: 15px;
  text-transform: uppercase;
}
.footerItemImage {
  width: 182px;
  @media screen and (max-width: 1000px) {
    text-align: center;
  }
}
.footerContentItem {
  width: 50%;
  @media screen and (max-width: 1000px) {
    width: 100%;
    text-align: center;
  }
}
.mobileFooterMT {
  margin-top: 0px;
  @media screen and (max-width: 1000px) {
    margin-top: 20px;
  }
}
.footerSocial {
  width: 32px !important;
  margin-left: 4px;
  margin-right: 4px;
  @media screen and (max-width: 1000px) {
    width: 26px !important;
  }
}
.footerSocialContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
  width: 182px;
}
.footerImageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 180px;
  align-items: center;
  @media screen and (max-width: 1000px) {
    max-width: 206px;
    margin: 0 auto;
  }
}
.footerLogo {
  background-color: white;
  width: 158px;
  height: 158px;
  padding: 10px;
  border-radius: 50%;

  // @media screen and (max-width: 1000px) {
  //   width: 135px;
  //   height: 125px;
  // }
}

.greenCheck {
  padding-top: 10px;
  width: 35px;
  height: 35px;
  align-self: center;
}

.footerTitle {
  color: $primary;
  font-weight: bold;
  border-bottom: 1px solid #000;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;

  padding-bottom: 3px;
}
.footerfont {
  font-family: "Lato" !important;
}
.flexCol {
  font-family: "Lato-Regular" !important;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flexCol > a {
  margin-top: 5px;
  color: $primary;
}
.flexCol > a:hover {
  color: darken($primary, 20);
}
.regularLink {
  color: #3273dc !important;
}

.btnSeeMore {
  width: 226px;
  height: 79px;
  font-size: 35px;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    width: 180px;
    height: 50px;
    font-size: 18px;
  }
}
.liftPageLiftTitle {
  font-size: 40px;
  font-family: Lato;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    font-size: 28px;
    text-align: center;
  }
}
.liftPageLiftTitleOrganization {
  font-size: 40px;
  font-family: Lato;
  font-weight: bold;

  @media screen and (max-width: 769px) {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
  }
}

.liftPageLiftStory {
  font-size: 20px;
  font-family: Lato;
  font-weight: normal;
  margin-bottom: 30px;
  @media screen and (max-width: 769px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.liftPageLiftStorySmall {
  font-size: 18px;
  font-family: Lato;
  font-weight: bold;
  margin-bottom: 30px;
  @media screen and (max-width: 769px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.errorSub {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.errorSubPublic {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.liftPageLiftName {
  font-size: 25px;
  font-family: Lato;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    font-size: 20px;
    text-align: center;
  }
}
.liftPageLiftNameOrganization {
  font-size: 25px;
  font-family: Lato;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    font-size: 18px;
    text-align: center;
  }
}
.organizationSubTitle {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}
.organizationLink {
  color: #0000ee !important;
  cursor: pointer;
}
.liftStoryOrganization > div {
  margin-top: 20px;
  margin-bottom: 20px;
}
.subscriptionMainContainer {
  background-color: $primary;
  color: white;
  text-align: center;
  border-color: white !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
.subscriptionContainerTitle {
  font-size: 24px;
  font-weight: bold;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
  @media screen and (max-width: 769px) {
    font-size: 20px;
  }
}
.width300 {
  width: 300px;
}
.subscription > input[type="text"] {
  width: 300px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 20px;
  color: $primary;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  @media screen and (max-width: 769px) {
    width: 200px;
  }
}
.subscription > input[type="text"]::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px;
  line-height: 16px;
  color: $primary !important;
  text-align: center;
}
.subscription > input[type="text"]::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px;
  color: $primary !important;
  text-align: center;
}
.subscription > input[type="text"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px;
  color: $primary !important;
  text-align: center;
}
.emailSub {
  width: 520px;
  margin: 0 auto;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  @media screen and (max-width: 769px) {
    max-width: 342px;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 370px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.buttonEmailSub {
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: 769px) {
    width: 136px;
    font-size: 16px;
  }
  @media screen and (max-width: 370px) {
    margin-top: 20px;
  }
}
.liftPageLiftProgressContainer {
  width: 100%;
  @media screen and (max-width: 769px) {
    width: 60%;
    min-width: 200px;
    margin: 0 auto;
  }
}
.liftPageLiftProgress {
  @media screen and (max-width: 769px) {
    height: 10px;
  }
}
.liftPageLiftProgressInfo {
  font-size: 18px;
  font-family: Lato;
  font-weight: bold;

  @media screen and (max-width: 769px) {
    font-size: 16px;
    font-weight: normal;
  }
}
.liftPageLiftProgressRaised {
  font-size: 30px;
  font-family: Lato;
  font-weight: bold;

  @media screen and (max-width: 769px) {
    font-size: 20px;
    font-weight: normal;
  }
}
.liftPageLiftLocation {
  font-size: 18px;
  font-family: Lato;
  font-weight: bold;
  font-style: italic;
  padding-top: 5px !important;
  @media screen and (max-width: 769px) {
    padding-top: 0px !important;
    font-size: 14px;
    text-align: center;
  }
}
.defaultBtnRadius {
  border-radius: 4px !important;
}
.liftPageLiftAbout {
  font-size: 18px;
  font-family: Lato;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}
.liftPageLiftContributor {
  font-size: 22px;
  font-family: Lato;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    font-size: 22px;
  }
}
.browseLiftRequestsFont {
  font-size: 40px;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    margin-left: 25px;
    font-size: 25px;
  }
}
.activeLiftRocketfundTitle {
  font-size: 40px;
  font-family: Lato;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    font-size: 23px;
  }
}
.activeLiftRocketfundInfo {
  font-size: 25px;

  font-family: Lato;
  @media screen and (max-width: 769px) {
    font-size: 15px;
  }
}
.activeLiftRocketfundStory {
  font-size: 24px;
  text-align: center;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    font-size: 17px;
    text-align: center;
  }
}
.activeLiftBoxFontLocation {
  font-size: 21px;
  font-weight: bold;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
}
.activeLiftBoxFontTitle {
  font-size: 25px;
  font-weight: bold;
  font-family: Lato;
  line-height: 30px;
  @media screen and (max-width: 769px) {
    font-size: 11px;
    line-height: 15px;
  }
}
.activeLiftBoxFontProgress {
  font-size: 22px;
  font-family: Lato;
  margin-top: 10px;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
}
.activeLiftBoxFontProgressImportant {
  font-size: 22px;
  font-weight: bold;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
}
.progressMobileHeight {
  @media screen and (max-width: 769px) {
    height: 10px;
  }
}
.liftHelpContainer {
  margin-bottom: 22px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* justify-content: center; */
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.needALiftContainer {
  font-size: 28px;
  font-family: Lato;
  font-weight: bold;
  margin-bottom: 10px;
  @media screen and (max-width: 769px) {
    font-size: 20px;
  }
}
.getALiftBtn {
  width: 180px;
  height: 50px;
  font-family: Lato;
  font-size: 22px;
}
.needALiftContainerText {
  font-size: 15px;
  font-family: Lato;
  margin-top: 20px;
  @media screen and (max-width: 769px) {
    font-size: 12px;
  }
}
.activeLiftBoxFont {
  font-size: 22px;
  font-family: Lato;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  @media screen and (max-width: 769px) {
    font-size: 18px;
  }
}
.activeLiftMainBody {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.hidden-with-dots {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.height-31 {
  height: 31px;
}
.height-96 {
  height: 96px;
}
.btnSeeMore:hover {
  border-color: darken($danger, 20%) !important;
  color: darken($primary, 20%) !important;
  background-color: white !important;
}
.ActiveLiftContainerLifts {
  @extend .liftRocketBody;
  // padding-left: 35px;
  // padding-right: 35px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  @media screen and (max-width: 769px) {
    max-width: 512px;
    @media screen and (max-width: 513px) {
      width: 100%;
    }
  }
}
.activeLiftBodayContainer {
  @extend .liftRocketBody;
  // padding-left: 35px;
  // padding-right: 35px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  @media screen and (max-width: 1217px) {
  }
  @media screen and (max-width: 769px) {
    width: 100%;
    //; padding-left: 10px;
    // padding-right: 10px;
  }
}
// .activeLiftBodayContainer::after {
//   content: "";
//   -webkit-box-flex: 1;
//      -moz-box-flex: 1;
//       -ms-flex: auto;
//           flex: auto;
//
// }
.mediaVideoContainer {
  width: 45%;
  @media screen and (max-width: 769px) {
    padding: 25px;
    padding-top: 5px;
    width: 100%;
    max-width: 560px;
  }
}
.mediaContentContainer {
  width: 55%;
  padding: 10px !important;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
}
.mediaContainer {
  @extend .liftRocketBody;
  padding: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 769px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.padding-lr-14 {
  padding-left: 14px;
  padding-right: 14px;
}
.activeLiftBox {
  padding: 10px;

  width: 33.3%;
  @media screen and (max-width: 769px) {
    width: 50%;
  }
}

.f-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.margin-0-auto {
  margin: 0 auto;
}
.width-380 {
  width: 380px;
}
.width-328 {
  width: 328px;
}
.overflow-scroll {
  overflow: scroll;
}

.mediaActionContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.font-9-7 {
  font-family: Lato;
  font-size: 9.7px;
}
.font-11 {
  font-family: Lato;
  font-size: 11px;
}
.font-12-5 {
  font-family: Lato;
  font-size: 12.5px;
}
.font-15 {
  font-family: Lato;
  font-size: 15px;
}
.font-12 {
  font-family: Lato;
  font-size: 12px;
}
.font-16 {
  font-family: Lato;
  font-size: 16px;
}
.font-17 {
  font-family: Lato;
  font-size: 17px;
}
.font-18 {
  font-family: Lato;
  font-size: 18px;
}
.font-20 {
  font-family: Lato;
  font-size: 20px;
}
.font-21 {
  font-family: Lato;
  font-size: 21px;
}
.font-22 {
  font-family: Lato;
  font-size: 22px;
}
.font-25 {
  font-family: Lato;
  font-size: 25px;
}
.font-30 {
  font-family: Lato;
  font-size: 30px;
}
.font-36 {
  font-family: Lato;
  font-size: 36px;
}
.font-48 {
  font-family: Lato;
  font-size: 48px;
}
.font-50 {
  font-family: Lato;
  font-size: 50px;
}
.bold {
  font-weight: bold;
}
.bold-700 {
  font-weight: 700;
}
.height-84 {
  height: 84px;
}
.mt-84 {
  height: 84px;
}

.liftActionBtn {
  font-weight: bold;
  font-size: 25px;
  width: 200px;
  font-family: Lato;
  @media screen and (max-width: 769px) {
    width: 150px;
    line-height: 18px;
    font-size: 18px;
  }
}
.seeAllBtn {
  font-weight: bold;
  font-size: 20px;
  font-family: Lato;

  @media screen and (max-width: 769px) {
  }
}
.liftActionSideBtn {
  font-weight: bold;
  font-size: 25px;
  width: 200px;
  font-family: Lato;
  margin-top: 30px;
  @media screen and (max-width: 769px) {
    width: 150px;
    height: 40px;
    font-size: 22px;
  }
}
.liftActionBtnContainer {
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.font-20 {
  font-size: 20px;
  font-family: Lato;
}
.font-24 {
  font-size: 24px;
  font-family: Lato;
}
.font-32 {
  font-size: 32px;
  font-family: Lato;
}
.font-42 {
  font-size: 42px;
  font-family: Lato;
}
.bold {
  font-weight: bold;
}

.padding-10 {
  padding: 10px;
}
.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.flex-space-around {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 12px;
  margin-bottom: 12px;
}
.height50 {
  height: 50px;
  width: 100%;
}
.backgroundWhite {
  background-color: white !important;
}
.stickyContainerZ10 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.lightgrey {
  background-color: #f7f7f7;
  color: black;
  font-family: "Lato-Semibold" !important ;
}

.whitetext {
  color: white;
  font-family: "Lato-Semibold" !important ;
  border-style: solid;
  border-color: white !important ;
}

.widthfull {
  width: 90%;
}
.mb-2 {
  margin-bottom: 12px;
}
.btnSpace {
  margin-top: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 769px) {
    margin-bottom: 0px;
  }
}
.nextBtn {
  box-shadow: 5px 10px 20px rgb(155, 148, 148) !important;
  color: $primary !important;
  background-color: white !important;

  border-radius: 25px 25px 25px 25px !important;
  @media screen and (max-width: 769px) {
    box-shadow: none !important;
    color: white !important;
    background-color: $warning !important;
    border-radius: 25px 0px 0px 25px !important;
  }
}
.confirmBtn {
  box-shadow: 5px 10px 20px rgb(155, 148, 148) !important;
  color: white !important;
  background-color: $warning !important;
  width:377px!important;
  height:55.34px!important;
  border-radius:4px!important;
  text-transform:uppercase;
  font-size:13px;

  
  @media screen and (max-width: 769px) {
    font-size:12px;
    box-shadow: none !important;
    width:327px!important;
    height:48px!important;
    
  }
  @media screen and (max-width: 400px) {
    width:100%!important;
  }
}
.nextBtnSign {
  display: none;
  @media screen and (max-width: 769px) {
    display: block;
  }
}
.lockIcon {
  margin-right: 10px;
}
.btnShadow {
  box-shadow: 5px 10px 20px rgb(155, 148, 148);
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pt-2 {
  padding-top: 12px !important;
}
.pb-2 {
  padding-bottom: 12px;
}
.flex-center-x {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.flex-justify-content-space-around {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.fDirectionRowReverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.padding-top-0 {
  padding-top: 0px !important;
}
.reqeustLiftContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  width: -moz-calc(33.33% - 1rem);
  min-width: 190px;

  width: calc(33.33% - 1rem);
  max-width: 310px;
  padding: 1rem;

  margin: 0.5rem 0.5rem;
}
.imageContain {
  -o-object-fit: contain;
  object-fit: contain;
  max-height: 335px;
}
.reqeustLiftContainerMedia {
  padding-bottom: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.requestLiftPageBody {
  margin: auto auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.requestLiftPageBody::after {
  content: "";
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.requestedalign {
  text-align: right;
}
.font-primary {
  font-family: Roboto;
  line-height: 1;
}

.MuiSlider-root {
  color: $primary !important;
  width: 80% !important;
}
.slider {
  width: 100%;
}
.margin-top-10 {
  margin-top: 10px;
}
.modalContainer {
  overflow-x: scroll;
  overflow-y: scroll;
}
.popup-content {
  width: 400px !important;
}
.editImageContainer {
  position: relative !important;
  height: 200px;

  margin: 0 auto;
  width: 300px;
}
.width300 {
  width: 300px;
}
.footerIconBox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.footerIconContainer {
  width: 200px;
  margin: 0 auto;
}
// .footerItem{
//   text-align: center;
//   color: $danger;
//   font-size: 25px;
//   font-family: Lato;
//   @media screen and (max-width: 769px){
//     font-size: 18px;

//   }
// }

.footerItem {
  text-align: left;
  color: $primary;
  font-size: 15px;
  text-transform: uppercase;
}
.fontLato {
  font-family: Lato;
}
.footerItem:hover {
  color: darken($danger, 20%);
}
.flexCol {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flexCol > a {
  margin-top: 5px;
  color: $primary;
}
.flexCol > a:hover {
  color: darken($primary, 20);
}
.defaultLink {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}
.shareTitle {
  margin-top: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.columnWidth158 {
  width: 158px;
  padding: 0.75rem;
}
.addLiftBtn {
  width: 100%;
}
.marginDownZero {
  margin-bottom: 0 !important;
}
.shareContent {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.wrap {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.shareIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.imagemwh330 {
  max-height: 330px;
  max-width: 330px;
  -o-object-fit: contain;
  object-fit: contain;
}
.bankInfo {
  color: white;
  margin: 10px auto;
}
.fontJustify {
  text-align: justify;
}
.image330h420w {
  height: 330px;
  width: 420px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.imagewh330 {
  height: 330px;
  width: 330px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.imagewh350 {
  height: 350px;
  width: 350px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.imagemwh230 {
  max-height: 230px;
  max-width: 230px;
  -o-object-fit: contain;
  object-fit: contain;
}
.imagemwh300 {
  max-height: 300px;
  max-width: 300px;
  -o-object-fit: contain;
  object-fit: contain;
}
.imagemwh250 {
  max-height: 250px;
  max-width: 250px;
  -o-object-fit: contain;
  object-fit: contain;
}
.liftPicture {
  height: 200px;
  width: 300px;
  line-height: 200px;
  border: 1px solid white;
  border-radius: 20px;
}
.imagewh250 {
  height: 250px;
  width: 250px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.activityBtn {
  width: 100px;
}

.letterspacingBtn {
  letter-spacing: 0.06em;
}

.flex-a-i {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.liftProgressLeftside {
  width: 100px !important;
}
.sentResponse {
  height: 48px;
  line-height: 48px;
}
.liftProgressLeftsideAbsolute {
  width: 100px;
  position: absolute;
  z-index: 1;
}
.d-f {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.font-2 {
  font-size: 2rem;
  font-family: Lato;
}
.d-f-r {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.d-f-c {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.j-c-c {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.a-i-c {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.verticleLine {
  border-left: 4px solid #ff5f58;
  height: 20px;
  margin-left: 248px;
}
.lineHeight-1 {
  line-height: 1;
}
.person {
  width: 50px;
}
.liftProgressItem {
  width: 300px;
}

span.readMoreText {
  font-weight: bold;
  color: $danger !important;
}

span.readLessText {
  font-weight: bold;
  color: $danger !important;
}

.liftProgressItemCenter {
  width: 200px;
}
.jc-s {
  width: 200px;
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.jc-e {
  width: 100px;
  text-align: right;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.jc-c {
  width: 100px;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.progress {
  margin-bottom: 0px !important;
}
.liftProgress {
  margin-top: 40px;
  margin-bottom: 2rem;
}
.mtBar {
  margin-top: 60px;
}
.plegesPerson {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}
.liftProgressContent {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.textLeft {
  text-align: left;
}
.mr-35 {
  margin-right: 35px;
}
.mt-116 {
  margin-top: 116px;
}
.primary-line {
  border-top: 1px solid $primary;
}

.timeline .timeline-item .timeline-marker.big-tc {
  width: 19px;
  height: 19px;
}
.organizationContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.organizationContainerPicture {
  width: 116px;
  height: 116px;
  padding: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.organizationContainerInfo {
  width: 150px;
  height: 116px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.organizationMainContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  @media screen and (max-width: 769px) {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.liftInviteFriends {
  margin: 1rem auto;
  width: 470px;
}
.labelMargin.label:not(:last-child) {
  margin-bottom: 0;
  margin-top: 1rem;
  font-family: "Lato-Semibold" !important ;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.03em;
  
}

.hasTextLeft {
  text-align: left !important;
}

.table-body-background-color {
  background-color: transparent;
}
.modal {
  position: absolute;
  z-index: 2000;
}

.sameHeightDivPic {
  border-radius: 20px;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.sameHeightImage {
  height: 200px;
}

iframe[id^="plaid-link-iframe"] {
  height: 100%;
}

.btn-link {
  @extend a;
  padding-right: 1rem !important;
}

.landing-bg {
  background-image: url("https://i.imgur.com/YORBFr8.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.inputCenter > div > .control {
  width: 210px;
  margin: 0 auto;
  margin-top: 20px;
}
.has-button-border-primary {
  .button {
    border-color: $primary !important;
  }
}
.has-button-border-white {
  .button {
    border-color: $white !important;
  }
}
.mt-1 {
  margin-top: 10px;
}
.mt-5px {
  margin-top: 5px !important;
}
.mt-15px {
  margin-top: 15px !important;
}
.mb-5px {
  margin-bottom: 5px !important;
}
.mt-2 {
  margin-top: 20px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-5 {
  margin-bottom: 110px;
}
.mt-4 {
  margin-top: 60px;
}
.pt-4 {
  padding-top: 60px;
}
.pt-4px {
  padding-top: 4px!important;
}
.pt-0px {
  padding-top: 0px!important;
}
.mtb-2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.contributorTitle {
  margin-top: 50px !important;
  margin-bottom: 40px !important;
  @media screen and (max-width: 769px) {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
}
.flex-jc-sb {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.c-w {
  color: $white;
}
.mobileIcon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.googleMobileIcon {
  width: 185px;
}
.appleMobileIcon {
  width: 163px;
  padding: 10px;
}
.wordWrap {
  overflow-wrap: break-word;
  width: 100%;
}

/* CSS talk bubble */
.talk-bubble {
  overflow-wrap: break-word;
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 200px;
  height: auto;
  background-color: lightyellow;
}
.border {
  border: 8px solid #666;
}
.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow transparent transparent lightyellow;
}

.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: lightyellow lightyellow transparent transparent;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.chat {
  width: 300px;
  /*   border: solid 1px #EEE; */
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 auto;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: rgb(220, 121, 121);
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: rgb(220, 121, 121);
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

@media screen and (max-width: 769px) {
  .mobile-padding-lr-14 {
    padding-left: 14px;
    padding-right: 14px;
  }

  .widthfull {
    width: 100%;
  }

  .activeLiftTitle {
    padding: 3px !important;
    margin: 3px !important;
  }
  .reqeustLiftContainer {
    padding: 3px !important;
    margin: 3px !important;

    width: -moz-calc(50% - 6px) !important;

    width: calc(50% - 6px) !important;
    min-width: 0px;
  }

  .editImageContainer {
    position: relative !important;
    height: 120px;

    margin: 0 auto;
    width: 200px;
  }
  .popup-content {
    width: 230px !important;
  }
  .sameHeightImage {
    border-radius: 20px;
  }

  .imageContain {
    -o-object-fit: contain;
    object-fit: contain;
    max-height: 100%;
  }

  .image330h420w {
    height: 220px;
    width: 280px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .liftInviteFriends {
    margin: 1rem auto;
    width: 300px;
  }
  .columnWidth158 {
    width: 214px;
    padding: 0.75rem;
  }

  .m-f-c {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .liftProgressLeftside {
    margin-bottom: 10px;
  }
  .mt-116 {
    margin-top: 0px;
  }
  .mt-4 {
    margin-top: 40px;
  }
  .mr-35 {
    margin-right: 0px;
  }
}
.mt-zero {
  margin-top: 0px !important;
}
.mb-zero {
  margin-bottom: 0px !important;
}

.OrangeTimeLineDesktopBox {
  display: flex;
  align-items: flex-start;
  // @media screen and (max-width: 820px){
  // justify-content: center;
  // width: 100%;
  // }
}
.OrangeTimeLineDesktopBox2 {
  display: flex;
  align-items: flex-start;
  margin-top: 32px;
  @media screen and (max-width: 820px){
    margin-top: 18px;
  }
}
.OrangeTimeLineMobileBox {
  width: 50%;
  display: flex;
  align-items: flex-start;
  height: 222px;
}
.OrangeTimeLineDesktopBoxText {
  max-width: 431px;
  height: 96px;
  margin-left: 10px;
  padding-right: 10px;
}
.OrangeTimeLineMobileBoxText {
  height: 100px;
  margin-left: 10px;
  text-align: left;
  font-size: 12px;
  font-family: "Lato-Semibold";
  width: 100%;
}

.OrangeTimeLineMobileBoxText1 {
  height: 30px;
  margin-left: 10px;
  text-align: left;
  font-size: 14px;
  font-family: "Lato-Semibold";
  width: 100%;
  line-height: 18px;
  @media screen and (max-width: 1000px){
    height: 46px;
  }
}

.OrangeTimeLineMobileBoxText2 {
  height: 54px;
  margin-left: 10px;
  text-align: left;
  font-size: 14px;
  font-family: "Lato-Semibold";
  width: 426px;
  line-height: 18px;
  @media screen and (max-width: 1000px){
    width: 100%;
    height: 80px;
  }
}
.OrangeTimeLineMobileBoxText3 {
  height: 54px;
  margin-left: 10px;
  text-align: left;
  font-size: 14px;
  font-family: "Lato-Semibold";
  width: 426px;
  line-height: 18px;
  @media screen and (max-width: 1000px){
    width: 100%;
    height: 72px;
  }
}
.OrangeTimeLineMobileBoxText4 {
  height: 67px;
  margin-left: 10px;
  text-align: left;
  font-size: 16px;
  font-family: "Lato-Semibold";
  width: 426px;
  line-height: 18px;
  text-transform: none;
  @media screen and (max-width: 1000px){
    font-size: 11px;
    width: 100%;
    height: 54px;
    padding-right: 10px;
  }
}

.fastWayToALiftTimeLine {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    margin-left: 33.4px;
    margin-right: 39px;
  }
}
.fastWayToALift-mobile {
  padding-top: 20px;
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
  }
}
.fastWayToALift-desktop {
  // display: block;
  @media screen and (max-width: 820px) {
    // display: none;
  }
}

.OrangeTimeLine-2 {
  width: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.OrangeTimeLine-3 {
  width: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 38px;
  @media screen and (max-width: 1000px) {
    margin-top: 3px;
    margin-left: 20px;
  }
}
#bankBtn3 {
  width: 299px!important;
  height: 50px!important;
  font-size: 14!important;
  line-height: 14px!important;
  padding-top: 18px!important;
  padding-bottom: 18px!important;
  font-family: "Lato";
  text-transform: uppercase;
  color: white!important;
  background-color: $primary!important;
  margin-top: 22px!important;
  margin-bottom: 0px!important;
  @media screen and (max-width: 1000px) {
    width: 209px!important;
    height: 35px!important;
    font-size: 10!important;
    line-height: 10px!important;
    padding-top: 12.5px!important;
    padding-bottom: 12.5px!important;
    margin-top: 0px!important;
    margin-bottom: 10px!important;
  }

}
.verticalLine-1 {
  height: 18px;
  border-left: 1px solid #f8c1ab;
  // margin-top: 5px;
  // margin-bottom: 5px;
  @media screen and (max-width: 1000px) {
    height: 34px;
  }
}
.verticalLine-2 {
  height: 38px;
  border-left: 1px solid #f8c1ab;
  // margin-top: 5px;
  // margin-bottom: 5px;
  @media screen and (max-width: 1000px) {
    height: 68px;
  }
}
.verticalLine-3 {
  height: 37px;
  border-left: 1px solid #f8c1ab;
  // margin-top: 5px;
  // margin-bottom: 5px;
  @media screen and (max-width: 1000px) {
    height: 51px;
  }
}
.verticalLine-4 {
  height: 54px;
  border-left: 1px solid #f8c1ab;
  // margin-top: 5px;
  // margin-bottom: 5px;
  @media screen and (max-width: 1000px) {
    height: 42px;
  }
}
.verticalLine-28 {
  height: 28px;
  border-left: 1px solid #f8c1ab;
  margin-top: 5px;
  margin-bottom: 5px;
}

.verticalLine-58 {
  height: 58px;
  border-left: 1px solid #f8c1ab;
  margin-top: 5px;
  margin-bottom: 5px;
}

.orangeCircleOutsideBorder {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: $warning solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orangeCircle {
  width: 11px;
  height: 11px;
  background-color: $warning;
  border-radius: 50%;
}
.orangeCircle2 {
  width: 9px;
  height: 9px;
  background-color: $warning;
  border-radius: 50%;
  @media screen and (max-width: 1000px) {
    width: 6px;
    height: 6px;
  }
}

.orangeCircleOutsideBorder2 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: $warning solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1000px) {
    width: 12px;
    height: 12px;
  }

}

.fastWayToALiftContainer {
  padding-left: 0px;
  padding-right: 0px;
  @media screen and (max-width: 769px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media screen and (max-width: 320px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.fastWayToALiftTitle {
  font-size: 24px;
  font-family: "Lato";
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
}
#lr-navbar{
  margin-top: 17px;
}
.desktopOnly {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
.mobileOnly {
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
  }
}
.mobileNav {
  display: none;
  background-color: white;
  color: $primary;
  @media screen and (max-width: 1000px) {
    display: flex;
  }
}
.mobileNavWhite {
  display: none;
  background-color: white;
  color: $primary;
  @media screen and (max-width: 1000px) {
    display: flex;
  }
}
.mobileNavPrimary {
  display: none;
  background-color: $primary;
  color: white;
  @media screen and (max-width: 1000px) {
    display: flex;
  }
}
.mobileSubNav {
  display: flex;
}
div.burger-menu-wrapper .bm-burger-button {
  top: 30px !important;
  width: 32px !important;
  height: 24px !important;
}

#leftSide {
  position: relative;
  display: flex;
  width: 90px !important;
  text-align: center;
  margin-top: 40px;
  text-align: center;
}
.rightSide {
  display: none;
  @media screen and (max-width: 1000px) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}
.headerItemImage {
  width: 114px;
  @media screen and (max-width: 1000px) {
    width: auto;
  }
}
.headerLogo {
  background-color: white;
  width: 135px;
  height: 135px;
  padding: 8px;
  padding-top: 23px;
  border-radius: 50%;

  @media screen and (max-width: 1000px) {
    padding-top: 8px;
    width: 62px;
    height: 62px;
    display: inline-block;
  }
}
.signInText {
  font-size: 10px;
  font-style: italic;
  font-family: "Lato-light";
  color:$primary !important;
  line-height: 8px;
}
.signInTextWhite {
  font-size: 10px;
  font-style: italic;
  font-family: "Lato-light";
  color: white !important;
  line-height: 8px;
}
.signInTextPrimary {
  font-size: 10px;
  font-style: italic;
  font-family: "Lato-light";
  color:$primary !important;
  line-height: 8px;
}
.empty {
  position: relative;
  display: flex;
  justify-content: left;
}
.mainNavContainer {
  @media screen and (max-width: 1000px) {
  box-shadow: 5px 10px 20px rgb(155, 148, 148) !important;
  margin-bottom: 20px;
  }
  
}
.mobileNavLinkSlide {
  font-size: 16px;
  font-family: "Lato-Semibold"!important;
}
.backgroundWhite{
  background-color: white!important;
}
.backgroundPrimary{
  background-color:$primary!important;
}
.colorWhite{
  color: white!important;
}
.colorPrimary{
  color:$primary!important;
}
.signOutDesktopBtn {
  width: 159px;
  height: 53px;
  background-color: white;
  color: $primary;
  font-size: 18px;
  font-family: "Lato";
  margin-left: 40px;
  margin-top: 20px;
}