div.loginwith {
  margin-top: 25px;
  width: 300px;

  .button {
    border: none;
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;
  }

  .or {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -moz-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;

    hr {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background-color: $light;
      height: 1px;
    }

    p {
      color: $light;
      margin: 10px 10px;
    }
  }
}

.facebook {
  width: 100%;
  background: #4267b2;
  color: white;

  &:hover {
    color: white !important;
    background: darken(#4267b2, 3%);
  }
}

.google {
  background: white;
  color: #444;

  &:hover {
    color: #444 !important;
    background: darken(white, 3%);
  }
}





/* Shared */
.loginBtn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 10px 0px;
  padding: 0 15px 0 46px;
  border: none;
  text-align: center;
  line-height: 36px;
  height: 36px;
  white-space: nowrap;
  border-radius: 20px;
  font-size: 16px;
  color: #FFF;
  width: 300px !important;
}

.loginBtn:before {
  content: "";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  -webkit-box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}


/* Google */
button.loginBtn--facebook {}

/* Facebook */
.loginBtn--facebook {
  background-color: #ff5f58;
}

.loginBtn--facebook:before {
  border-right: #ff5f58 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 8px 6px no-repeat;

}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background: #E74B37;
}



/* Google */
button.loginBtn--google {}

.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #ff5f58;
}

.loginBtn--google:before {
  border-right: #ff5f58 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 8px 6px no-repeat;

}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

.loginTitle {
  padding-top: 94px;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;

  @media screen and (max-width: 1000px) {
    padding-top: 48.5px;
    font-size: 16px;
  }
}

.loginPageBtn {
  width: 258.14px !important;
  height: 71.61px !important;
  font-size: 19px;
  line-height: 19px !important;
  padding-bottom: 26.3px !important;
  padding-top: 26.3px !important;
  font-weight: bold;
  font-family: "Lato";
  margin-top: 55px;
  letter-spacing: 0.1rem;

  @media screen and (max-width: 1000px) {
    width: 189.77px !important;
    height: 52.64px !important;
    font-size: 13px;
    line-height: 13px !important;
    padding-bottom: 19.82px !important;
    padding-top: 19.82px !important;
    margin-top: 33.9px;
  }

}

.loginBottonText1 {
  margin-top: 53.6px;
  font-family: "Lato-Light";
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0.02rem;
  line-height: 37px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    margin-top: 29.4px;
  }

}

.loginBottonText1>a {
  color: $primary;
}

.loginBottonText2 {
  margin-top: 18.9px;
  font-family: "Lato-Light";
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 40px;
  letter-spacing: 0.02rem;
  line-height: 37px;

  @media screen and (max-width: 1000px) {
    font-size: 16px;
    margin-top: 20.2px;
    margin-bottom: 78.5px;
  }
}

.loginBottonText3 {
  font-weight: bold;
}

#loginInput {

  border-radius: 32px !important;
  width: 396.5 !important;
  height: 59px !important;
  border: none;
  padding-left: 40px;


  background-color: white !important;
  font-size: 22px !important;
  font-family: "Lato" !important;
  margin-top: 38px;
  box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;

  @media screen and (max-width: 1000px) {
    width: 268.99px !important;
    height: 55.98px !important;
    font-size: 16px !important;
    margin-top: 29px;
  }
}

.loginControl {
  display: flex;
  justify-content: center;
}

#loginInput::placeholder {

  font-size: 22px !important;
  // padding-left: 20px;
  font-weight: normal;
  color: $primary;
  font-family: "Lato";
  opacity: 1;

  @media screen and (max-width: 1000px) {

    font-size: 16px !important;
  }
}

.loginContainer {
  max-width: 400px;
  margin: auto;
  padding-left: 0px;
  color: $primary !important;
  font-family: "Lato" !important;
  display: flex;
  flex-direction: column;
}

#signupInput {

  border-radius: 32px !important;
  width: 343 !important;
  height: 51px !important;
  border: none;
  padding-left: 40px;


  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  margin-top: 38px;
  box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;

  @media screen and (max-width: 1000px) {
    width: 298px !important;
    height: 55.98px !important;
    font-size: 16px !important;
    margin-top: 29px;
  }
}

#signupInput2 {

  border-radius: 32px !important;
  width: 343 !important;
  height: 51px !important;
  border: none;
  padding-left: 40px;


  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  margin-top: 38px;
  box-shadow: 5px 10px 20px rgb(170, 168, 168) !important;

  @media screen and (max-width: 1000px) {
    width: 298px !important;
    height: 55.98px !important;
    font-size: 16px !important;
    margin-top: 29px;
  }
}

#signupInput::placeholder {

  font-size: 22px !important;
  // padding-left: 20px;
  font-weight: normal;
  color: $primary;
  font-family: "Lato";
  opacity: 1;

  @media screen and (max-width: 1000px) {

    font-size: 16px !important;
  }
}

#signupInput2::placeholder {

  font-size: 22px !important;
  // padding-left: 20px;
  font-weight: normal;
  color: $primary;
  font-family: "Lato";
  opacity: 1;

  @media screen and (max-width: 1000px) {

    font-size: 16px !important;
  }
}



#sportSignupInput {
  box-shadow: 0px 4px 4px 0px rgba(240, 110, 98, 1);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);



  width: 503.44px !important;
  height: 70.45px !important;


  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  margin-top: 24px;


  color: #4D5B77;
  padding-left: 24px;

  border-radius: 16px;
  border: 0.5px solid #4D5B77;
  background: #FFF;
  
  filter: drop-shadow(0px 4px 4px #F06E62) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));


  @media screen and (max-width: 600px) {
    width: 343px !important;
    height: 48px !important;
    font-size: 14px !important;
    margin-top: 16px;
    padding-left: 16px;
  }
}

.sportSignupSelect {

  border-radius: 32px !important;
  width: 503.44px !important;
  height: 70.45px !important;


  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;
  margin: 0 auto;
  margin-top: 24px;

  // border: 0.5px solid #4D5B77;
  border-radius: 16px !important;
  color: #4D5B77;
  // padding-left: 24px;


  @media screen and (max-width: 600px) {
    width: 343px !important;
    height: 48px !important;
    font-size: 14px !important;
    margin-top: 16px;
    // padding-left: 16px;
  }

  @media screen and (max-width: 400px) {
    width: 100% !important;

  }
}

div.sportSignupSelect.select::after {
  border-color: $primary !important;
}

.sportSignupSelect>select {
  border-radius: 32px !important;
  width: 503.44px !important;
  height: 70.45px !important;


  background-color: white !important;
  font-size: 18px !important;
  font-family: "Lato" !important;


  border: 0.5px solid #4D5B77;
  border-radius: 16px !important;
  color: #4D5B77;
  padding-left: 24px;


  @media screen and (max-width: 600px) {
    width: 343px !important;
    height: 48px !important;
    font-size: 14px !important;
    padding-left: 16px;
  }

  @media screen and (max-width: 400px) {
    width: 100% !important;

  }
}

#sportSignupInput::placeholder {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  opacity: 1 !important;

  /* or 18px */

  color: #4D5B77;

  @media screen and (max-width: 600px) {

    font-size: 14px !important;
  }
}

.sportSignUpBtn {
  box-shadow: 0px 4px 4px 0px rgba(240, 110, 98, 1);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  padding: 0px !important;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 503px !important;
  height: 60px !important;


  filter: drop-shadow(0px 4px 4px #F06E62) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  
  border-radius: 30px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;

  color: #F4F2F6;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 343px !important;
    height: 42px !important;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;


  }

  @media screen and (max-width: 400px) {
    width: 100% !important;
    position: relative;
  }

}

.sportSignupBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 32.55px;

  @media screen and (max-width: 600px) {
    margin-top: 16px;

  }
}

.sportBody {

  height: 1154px;
  background: white;
  padding-top: 60px;

  @media screen and (max-width: 600px) {
    padding-top: 35px;

    height: 732px;
    background: white;
  }

}
.sportMessage {
  height: 55px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  font-family: 'Lato';
  background-color: $primary;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 769px) {
    font-size: 11px;
    height: auto;
    padding-left: 14px;
    padding-right: 14px;
    line-height: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

}

.sportBodyForm {

  @media screen and (max-width: 400px) {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
  }

}

.sportBody2 {

  height: 986px;
  padding-top: 20px;

  @media screen and (max-width: 600px) {

    height: 681px;

  }

}

.sportForm {
  width: 490px;
  margin: 0 auto;
  // height: 1392px;

  @media screen and (max-width: 600px) {
    width: auto;
    // height: 812px;

  }
}

.sportForm2 {
  width: 832px;
  margin: 0 auto;
  // height: 1392px;

  @media screen and (max-width: 832px) {
    width: auto;
    // height: 812px;

  }
}

.sportNavDesktop {
  display: flex;
  justify-content: center;


  @media screen and (max-width: 600px) {
    display: none;

  }

}

.sportNavDesktop >img {
  width: 490px;
  height: 141px;
  

}

.sportNavMobile {
  display: none;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}



.sportNavMobile >img {
  width: 179px;
  height: 59px;
}



.sportTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  margin-top: 24px;
  color: $primary;
 

  @media screen and (max-width: 600px) {
    font-size: 22px;
    margin-top: 7px;
    width: 343px;
    display: flex;
    justify-content: left;
    margin-left: 18px;
    margin-bottom: 4px;
  }

  @media screen and (max-width: 400px) {
    width: 100% !important;

  }
}

.sportSub {

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-top: 20px;
  color: $primary;
  max-width: 442px;

  @media screen and (max-width: 600px) {
    margin-top: 17px;

    font-size: 14px;
    width: 343px !important;
    display: flex;
    justify-content: center;

  }

  @media screen and (max-width: 400px) {
    width: 100% !important;

  }
}

.sportTop {
  @media screen and (max-width: 600px) {
    width: 343px;
    margin: 0 auto;
  }

  @media screen and (max-width: 400px) {
    width: 100% !important;

  }
}

.sportSuccessImage {
  display: block;
  margin: 0 auto;
  width: 276px;
  height: 318.08px;
  margin-top: 67px;

  @media screen and (max-width: 600px) {
    width: 125px;
    height: 127px;
    margin-top: 49px;
  }
}

.sportSuccessText {

  text-align: center;
  // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 52px */

  /* or 45px */
  text-align: center;
  margin-top: 87px;
  color: $primary;

  @media screen and (max-width: 600px) {
    color: #4D5B77;
    text-align: center;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 48px */
    margin: 0 auto;
    margin-top: 28px;
    width: 280px;

  }

  @media screen and (max-width: 300px) {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.sportSuccessText2 {




  text-align: center;
  font-family: Lato;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 45.5px */

  /* or 45px */
  text-align: center;
  margin-top: 24px;
  color: $primary;


  @media screen and (max-width: 832px) {

    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 30.42px */
    width: 280px;
    margin: 0 auto;
    margin-top: 24px;

  }

  @media screen and (max-width: 300px) {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.errorCenter {
  text-align: center;
}

.sportSelect>.has-text-left {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sportSelect>.has-text-left>.radioButtonVals {
  width: 100%;
  display: flex;
}

.sportCheckboxContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 65px;
  @media screen and (max-width: 600px) {
    margin-bottom: 39px;
  }

}

.sportCheckboxContainerBox {
  color: $primary;
  font-family: Lato;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 21px */
  margin-top: 27px;

 

  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-top: 27px;
    margin-left: 4px;

  }

}
.sportCheckboxContainerBoxName{
  margin-left: 4px;
  @media screen and (max-width: 600px) {
    margin-left: 4px;
  }
}

.sportCheckboxBox {
  box-shadow: 0px 4px 4px 0px rgba(240, 110, 98, 1);

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;

  align-items: center;
  width: 240px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 0.5px solid #4D5B77;
  background: #FFF;
  filter: drop-shadow(0px 4px 4px #F06E62) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  color: $primary;
  font-family: Lato;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 19px */
  cursor: pointer;
  


  @media screen and (max-width: 600px) {

    width: 159px;
    height: 48px;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: normal;

  }
  @media screen and (max-width: 375px) {
    width: 120px;
    height: 48px;
    font-size: 12px;

  }
}

.sportCheckboxBoxImage {
  margin-left: 24px;
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media screen and (max-width: 600px) {
    margin-left: 12px;
    margin-right: 6px;
    width: 12px;
    height: 12px;

  }
}

.sportFooter {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  margin-bottom: 2px;
  cursor: pointer;
  width: 307px;

  @media screen and (max-width: 1439px) {

    margin-bottom: 0px;

  }
}

.sportCheckboxContainerEle {
  display: flex;
  width: 503px;
  justify-content: space-between;
  margin-top: 22px;

  @media screen and (max-width: 600px) {

    width: 343px;
    margin-top: 17px;

  }
  @media screen and (max-width: 375px) {

    width: 260px;
    

  }
  

  // @media screen and (max-width: 375px) {

  //   display: flex;
  //   flex-direction: column;
  //   width: auto;
  //   row-gap: 20px;
  //   column-gap: 20px;

  // }
}

.sportFooterText {
  display: flex;
  flex-direction: column;
  text-align: center;
  
}

.sportFooterText>div:first-child {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: $primary;

  @media screen and (max-width: 1439px) {
    font-size: 11px;
  }





}

.sportFooterText>div:nth-child(2) {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  margin-top: 9px;
  text-decoration: underline;
  color: $primary;
  @media screen and (max-width: 600px) {
    font-size: 8px !important;
    color: $primary !important;
    margin-top: 4px;
  }




}

a.sportPrivatePolicy {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  color: $primary;

  text-decoration: underline;
  margin-top: 4px;

  @media screen and (max-width: 600px) {
    font-size: 8px !important;
    color: $primary !important;

  }
}

.sportFooterBox {
  background-color: white;
  padding-top: 49px;
  padding-bottom: 20px;

  @media screen and (max-width: 600px) {
    padding-top: 0px;
  }
}

.desktopFlex {
  display: flex;
  width: 307px;
  height: 70px;

  @media screen and (max-width: 1439px) {
    display: none;
  }
}

.mobileFlex {
  display: none;

  @media screen and (max-width: 1439px) {
    display: flex;
    width: 264px;
    height: 55px;
  }
}

.liftrocket-privacy-container {
  height: 5972px;
  width: 1280px;
}

.liftrocket-privacy-container .liftrocket-privacy {
  color: #4d5b77;
  font-family: "undefined-Regular", Helvetica;
  font-size: 0.1px;
  left: 0;
  line-height: 0.1px;
  position: fixed;
  top: 0;
  width: 1280px;
}

.privatePolicyMain>h1.title {
  margin-bottom: 0px;
}

.privatePolicyMain ul ul {
  list-style-type: disc !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.privatePolicyMain>h1.title {
  margin-bottom: 0px;
}

.privatePolicyMain ul {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.privatePolicyMain ul ul ul {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.privatePolicyMain ol {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.privatePolicyMain ol ol {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.privatePolicyMain li+li {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.privatePolicyBody {
  margin-top: 30px !important;
}

.sportNav {

  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;


  @media screen and (max-width: 1439px) {

    margin-left: 0px;

  }
}

.sportNavBtnContainer {
  display: flex;
  column-gap: 29px;
  margin-right: 35px;

  @media screen and (max-width: 1439px) {
    margin-right: 25px;
  }
}

.sportNavBtn2 {
  width: 292px;
  height: 61px;

  border-radius: 30px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: linear-gradient(90.32deg, #4D5B77 53.92%, #F06E62 97.21%, #FEF3F2 130.49%, #FFFFFF 137.86%, #4D5B77 164.37%);
  box-shadow: 0px 4px 4px 0px rgba(240, 110, 98, 1);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFF;
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 1439px) {
    width: 114px;
    height: 26px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    background: linear-gradient(90.32deg, #4D5B77 38.23%, #F06E62 93.68%, #FEF3F2 130.49%, #FFFFFF 137.86%, #4D5B77 164.37%);


  }

}
.sportBackToHome {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: $link;
  text-align: center;
  margin-top: 62px;
  text-decoration: underline;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-top: 30px;
  }

}
.sportCCImage {
  margin-top: 76px;
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url("../svg/ccBackground.svg");
  background-repeat: no-repeat;
  background-position: center; 
  height: 400px;
  transform: rotate(2deg);
  @media screen and (max-width: 600px) {
    height: 300px;
    width: 280px;
    background-size: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }
}
.sportCCImage > img {
  margin-top: 20px;
  margin-left: 20px;
  transform: rotate(-2deg);
  width: 434px;
  @media screen and (max-width: 600px) {
    width: 241px;
  }
  
  
}
